.KioskWelcomeScreen {
  h2 {
    text-align: center;
    font-size: 72px;
    margin-top: -30px;
  }

  .WelcomeScreenMessage {
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    width: 100%;
    margin-bottom: 98px;
    margin-top: 30px;
  }

  .WelcomeScreenSteps {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;

    .WelcomeScreenStep {
      background-color: rgba(27, 167, 83, 0.1);
      border-radius: 37px;
      margin-left: 13px;
      margin-right: 13px;
      width: 344px;
      height: 256px;
      margin-bottom: 59px;
      display: block;

      .WelcomeScreenStepNumber {
        width: 64px;
        height: 53px;
        background-color: #1ba753;
        border-radius: 37px;
        color: white;
        margin: 0 auto;
        padding: 6px 0;
        transform: translateY(-22.5px);
        font-size: 28px;
        line-height: 38px;
      }

      .WelcomeScreenStepSingleIcon {
        font-size: 60px;
        color: #1ba753;
        margin-top: 20px;
      }

      .WelcomeScreenStepTitle {
        height: auto;

        p {
          font-size: 28px;
          line-height: 34px;
          width: 100%;
          padding: 0 12px;
          margin-bottom: 40px;
          margin-top: 35px;
          color: #1c3e52;
        }
      }
    }
  }
}

.WelcomeScreenStepCompositeIcon {
  width: 100%;
  margin-top: 0;

  svg {
    font-size: 55px;
    color: #1ba753;

    &:first-child {
      font-size: 27px;
    }
  }
}

.Step__number.WelcomeScreenCompositeNumber {
  width: 108px;
}
