.side-menu-container {
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08);
  height: 100%;
  min-height: 100vh;

  .location-logo {
    padding: 20px;
    text-align: center;

    img {
      width: 100%;
    }
  }

  .menu {
    button {
      width: 100%;
      background: none;
      border: none;
      text-align: left;
      outline: none;
      &:hover,
      &:active,
      &:focus {
        background: none;
      }
    }

    .item {
      padding: 25px;
      cursor: pointer;
      font-size: calc(16px + 2 * var(--width-ratio));
      color: #1c3e52;

      svg {
        margin-right: 15px;
      }

      &.bg-color {
        color: #ffffff;
        border-radius: 0;
      }
    }
  }
}
