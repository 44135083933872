@import url("https://use.typekit.net/glj5zod.css");

body {
  margin: 0;
  font-family: "proxima-nova";
  font-weight: 400;
  letter-spacing: 0;
  font-size: 16px;

  h1 {
    text-align: left;
    font-family: "proxima-nova";
    font-weight: 700;
    font-size: 28px;
    letter-spacing: 0;
    color: #1c3e52;
    opacity: 1;
    margin: 0;
  }

  h4 {
    font-family: "proxima-nova";
    font-weight: 500;
    margin: 0;
  }
}

@media (max-width: 1223px) {
  body {
    font-size: 13px;

    h1 {
      font-size: 20px;
    }
  }

  .-margin-bottom-mobile-10 {
    margin-bottom: 10px;
  }
}
