.PickUpScreen {
  .PickUpBox {
    height: 280px;
    width: 340px;
    margin: 89px auto 29px auto;
    background-color: rgba(224, 225, 226, 0.2);
    font-size: 13px;
    position: relative;

    .loader {
      top: 50%;
      left: 50%;
      transform: translateX(-92px) translateY(-66px);
      height: 128px;
      width: 128px;

      &:before,
      &:after {
        height: 128px;
        width: 128px;
      }
    }

    .PickUpBoxNumber {
      position: absolute;
      font-size: 160px;
      line-height: 195px;
      color: #1c3e52;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .PickUpMessage {
    width: 100%;
    text-align: center;
    font-size: 52px;
    line-height: 71px;
  }
}
