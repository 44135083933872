.KioskEnterRegistrationScreen {
  .EnterRegistrationHeaderIcon {
    font-size: 56px;
    margin-right: 10px;
    color: #1ba753;
  }

  .EnterRegistrationHeaderTitle {
    display: inline-block;
    margin-left: 10px;
    margin-bottom: 30px;
  }

  .EnterRegistrationScreenKeyboardContainer {
    transform: translateY(38em);
  }

  .EnterRegistrationScreenRegContainer {
    height: 230px;
    display: flex;
    justify-content: center;
    align-items: center;

    .reg-number-container {
      width: 733px;
      height: 172px;
      display: flex;
      border: 6px solid #000000;
      border-radius: 16px;
      margin: 0 auto 20px auto;

      .reg-country {
        background-color: #007df4;
        color: #fff;
        height: 100%;
        font-size: 29px;
        position: relative;
        width: 89px;
        overflow: hidden;

        .reg-country-text {
          position: absolute;
          width: 100%;
          bottom: 17px;
          text-align: center;
          font-size: 43px;
        }
      }

      .reg-value-container {
        width: 100%;
        height: 100%;
        background-color: #fcb000;
        color: #fff;
        display: flex;

        .reg-value-inner-container {
          width: 90%;
          height: 100%;
          margin: auto;
          padding: 17px 5px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }

        .reg-number {
          width: 79px;
          height: 116px;
          background-color: rgba(69, 65, 55, 0.2);
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 64px;
          color: #1c3e52;
        }
      }
    }
  }
}

.EnterRegistrationHelp {
  .EnterRegistrationHelpCode {
    display: inline-block;
    border-radius: 4px;
    background-color: rgba(224, 225, 226, 0.2);
    padding: 12px 25px;
    font-size: 46px;
    line-height: 62px;
    letter-spacing: 15px;
    margin-left: 50px;
  }

  .icon {
    font-size: 12px;
    color: white;
    background-color: #db2828;
    box-shadow: none;
    vertical-align: middle;
  }
}
