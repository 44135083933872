.KioskThankYouScreen {
  h2 {
    font-size: 52px;
  }

  .ThankYouImageContainer {
    text-align: center;
    width: fit-content;
    margin: 0 auto;
    background-color: #1ba7530e;
    padding: 33px 31px;
    border-radius: 37px;
    margin-top: 47px;

    img {
      width: auto;
      height: auto;
      border-radius: 37px;
    }
  }
}
