.close-btn {
  margin-left: 1em;
}

.ui.modal.popup-modal {
  .modal-content {
    padding: 0;
    border-radius: 0px;
  }
}

@media (min-width: 1920px) {
  .close-btn {
    margin-left: 2em;
  }
}

@media (max-width: 1223px) {
  .ContactUsSection,
  .ContactUsSection span {
    width: 100%;
    display: block;
    margin-left: 0 !important;
    margin-top: 10px;
  }

  .close-btn {
    margin-top: 1em;
    margin-left: 0em;
    display: block;
  }
}

@media (min-width: 769px) {
  .popup-modal {
    position: relative;
    .contact-us-section-box {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      position: absolute;
      bottom: 1em;
    }
  }
}
