.CancelScreen {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  p {
    text-align: center;
    font-size: 64px;
    line-height: 87px;
  }
}
