.KioskDisabledScreen {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 80%;

  > .KioskTitle {
    font-size: 64px !important;
  }

  .KioskDisabledIcon {
    width: 64px;
    height: 52px;
    color: #21ba45;
    margin: 0 10px 10px 0;
    vertical-align: middle;
  }

  .KioskDisabledMessage {
    font-size: 42px;
    line-height: 47px;
    margin-top: 40px;
  }

  .KioskDisabledCompositeIcon {
    display: inline-block;
    margin-right: 10px;

    svg {
      font-size: 64px;
      color: #1ba753;

      &:first-child {
        font-size: 66px;
        transform: translateY(90px);
      }
    }
  }
}
