.CheckInfoScreen {
  .CheckInfoMessage {
    font-size: 52px;
    line-height: 71px;
    margin: 24px 0;
  }

  .CheckInfoDetail {
    font-size: 52px;
    line-height: 71px;
    background-color: rgba(224, 225, 226, 0.2);
    border-radius: 4px;
    display: inline-block;
    padding: 18px;

    .icon {
      font-size: 36px;
      margin-right: 7px;

      &.phone {
        vertical-align: top;
      }
    }
  }

  .CheckInfoExtraPhone {
    margin: 48px 0 5px 0;
    font-size: 52px;
    line-height: 71px;
  }
}
