.Kiosk {
  color: #1c3e52;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  padding: 0 60px;
  min-width: 1080px;
  min-height: 1920px;

  .KioskHeader {
    height: 100px;
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 28px;
    line-height: 38px;

    .KioskHeaderStep {
      width: fit-content;
      color: white;
      border-radius: 37px;
      height: 42px;
      background-color: #1ba753;
      text-align: center;
      display: inline-block;
      margin: 14px 24px 14px 0px;
      padding: 0px 30px;
    }

    .KioskHeaderTitle {
      display: inline-flex;
      align-items: center;
      margin: 14px 0;
    }

    .KioskHeaderActions {
      display: flex;
      float: right;
      flex-direction: row;

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 36px;
        border-radius: 4px;
        background-color: #f9f9f9;
        width: 80px;
        height: 72px;
      }
    }
  }

  .KioskContent {
    height: calc(100vh - 100px - 210px);
    padding-top: 42px;

    .KioskTitle {
      font-size: 70px;
      line-height: 87px;
      margin-bottom: 24px;
    }
  }

  .KioskNavigation {
    height: 210px;
    padding: 39px 0 71px 0;

    .KioskNavigationLeftButton {
      .button {
        float: left;
        padding: 40px 45px;
        font-size: 38px;
        border-radius: 8px;
        min-width: 45%;
      }
    }

    .KioskNavigationMainButton {
      width: 100%;
      margin: 0 auto;
      text-align: center;

      .button {
        padding: 40px 60px;
        font-size: 42px;
        border-radius: 8px;
      }
    }

    .KioskNavigationRightButton {
      .button {
        float: right;
        font-size: 42px;
        border-radius: 8px;
        padding: 40px 45px;
        min-width: 45%;
      }
    }
  }

  .KioskNumPadContainer {
    margin: 0 auto;
    width: 100%;
    display: flex;
    justify-content: center;
    transform: translateY(70%);
  }
}
