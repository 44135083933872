.info-content-container {
  height: 100%;

  .info-container.component-container {
    border: 1px solid #b1b8bc;
    border-radius: 2px;
    margin-bottom: 10px;

    .info-heading {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      background-color: #f3f5f7;
      padding: 8px 10px;
    }

    .info-content {
      background-color: #fff;
      padding: 5px 10px;

      .info-icon {
        width: 16px;
        height: 18px;
      }

      .margin-bottom-8 {
        margin-bottom: 8px;
      }

      .info-text {
        font-size: 18px;
        line-height: 22px;
        color: #5c7c8d;
        margin-left: 0.5em;
      }

      .info-explanation-text {
        font-size: 18px;
        line-height: 25px;
        color: #698696;
      }

      .header-info-reg-num-box {
        width: calc(130px + 25 * var(--width-ratio));
        height: calc(24px + 6 * var(--width-ratio));
        margin-bottom: 8px;
        border: 2px solid #234457;
        border-radius: 0.15em;

        .header-info-reg-country {
          width: 21px;
          height: 100%;
          line-height: 100%;
          display: block;
          float: left;
          text-align: center;
          background-color: #40a7ff;
          padding-top: calc(3px + 2 * var(--width-ratio));
          position: relative;

          .header-info-reg-country-text {
            color: #fff;
            font-size: 10px;
            position: absolute;
            width: 100%;
          }
        }

        .header-info-reg-num {
          width: calc(100% - 21px);
          height: 100%;
          line-height: 100%;
          display: block;
          float: right;
          text-align: center;
          background-color: #efe24c;
          color: #234457;
          padding-top: calc(2px + 1 * var(--width-ratio));
          font-weight: 500;
          font-size: calc(16px + 4 * var(--width-ratio));
        }
      }

      .info-status-container {
        margin-bottom: 0.5em;
        margin-top: 0.3em;

        .info-status {
          height: 25px;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          .info-status-box {
            width: 25px;
            height: 25px;
            border-radius: 0.3em;
            display: inline-block;
            vertical-align: middle;
            display: inline-flex;
            align-items: center;
            justify-content: center;

            &.all-right {
              background-color: #6eb160;
            }

            &.advisable {
              background-color: #ffa500;
            }

            &.neccessary {
              background-color: red;
            }

            svg {
              color: white;
              width: 13px;
              height: 14px;
              margin: 6px 7px;
            }
          }

          .info-status-text {
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            margin-left: 0.7em;
            font-weight: 400;
            color: #9cabb4;
          }
        }

        .info-status-action-title {
          background-color: #f3f5f7;
          color: #234457;
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          margin: 0 -8px 16px -8px;
          padding: 8px;
        }

        .info-status-action-container {
          .info-status-action-legend-explainer {
            margin-top: 1em;
            color: #9cabb4;
          }

          .info-status-action {
            display: inline-block;
            &:first-child {
              margin-right: 15px;
              margin-bottom: 5px;
            }

            .info-status-action-box {
              display: inline-block;
              width: 39px;
              height: 20px;
              padding: 0.1em;
              border: 1px solid #0f39cc;
              border-radius: 0.2em;
              text-align: center;

              svg,
              span {
                color: #0f39cc;
                font-size: 1em;
              }
            }

            .info-status-action-text {
              font-size: 14px;
              line-height: 17px;
              color: #9cabb4;
              margin-left: 0.5em;
              font-weight: 300;
              margin-top: 0.1em;
            }
          }
        }
      }

      .line-height {
        height: 22px;
      }
    }

    .info-footer {
      padding: 0px !important;
      background-color: #fff;

      .column.info-footer-column {
        width: auto !important;
        margin-right: 40px;

        .info-footer-label {
          font-size: 18px;
          line-height: 22px;
          font-weight: 500;
          color: #234457;
        }

        .info-footer-data {
          font-size: 18px;
          line-height: 22px;
          color: #5b7b8c;
        }

        .info-footer-date {
          font-size: 18px;
          line-height: 22px;
          color: #5b7b8c;
          font-weight: bold;
        }
      }
    }
  }
}
