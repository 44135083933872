.AuthenticationOptionsScreen {
  width: 90%;
  margin: 0 auto;
  text-align: center;
  .KeyLockerTitle {
    text-align: center;
    width: 100%;
  }

  .AuthenticationOptionsScreenSubtitle {
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
  }

  .AuthenticationOptionsScreenOptions {
    display: flex;
    justify-content: center;
    margin: 0;

    &:first-child {
      .AuthenticationOptionsScreenOption {
        margin-left: 0px;
      }
    }

    &:last-child {
      .AuthenticationOptionsScreenOption {
        margin-right: 0px;
      }
    }

    .AuthenticationOptionsScreenOption {
      display: inline-flex;
      background-color: rgba(224, 225, 226, 0.2);
      border-radius: 37px;
      margin-left: 15px;
      margin-right: 15px;
      width: 300px;
      height: 338px;
      vertical-align: middle;
      justify-content: center;
      align-items: center;

      .AuthenticationOptionsScreenOptionContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 8px solid #3ca753;
        border-radius: 37px;

        .AuthenticationOptionsScreenSingleIcon {
          width: 80px;
          height: 60px;
          color: #3ca753;
        }

        .AuthenticationOptionsScreenIcon {
          width: 100%;
          height: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }

        .AuthenticationOptionsScreenCompositeIcon {
          margin-top: -30px;

          svg {
            width: 54px;
            height: 63px;
            color: #3ca753;

            &:first-child {
              width: 64px;
              height: 45px;
              transform: translateY(40%);
            }
          }
        }

        .AuthenticationOptionsScreenOptionTitle {
          font-size: 28px;
          line-height: 38px;
          width: 90%;
          font-weight: 600;
        }
      }

      &:nth-child(3) .AuthenticationOptionsScreenSingleIcon {
        font-size: 45px;
      }
    }
  }
}
