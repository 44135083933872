.report-working-items-checks-container.component-container {
  .working-items-checks-heading {
    margin-bottom: 15px;
    break-inside: avoid;
    background-color: #bdf0c4;
    padding: 5px 0px 5px 10px !important;
    display: flex;
    align-items: center;

    .working-items-checks-heading-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 8px;
      color: #234457;

      &.check.circle.outline {
        margin-right: 0.2em;
        margin-top: 0.2em;
      }
    }

    .working-items-checks-heading-title {
      margin: 0em;
      color: #1c3e52;
      font-weight: 500;
      font-size: calc(24px + 6 * var(--width-ratio));
      width: calc(100% - calc(24px + 6 * var(--width-ratio)) - 8px);
      margin-top: 3px;
    }
  }

  .working-items-checks-with-remarks {
    margin-bottom: 10px !important;

    .row {
      padding: 0;
      break-inside: avoid;

      .working-items-checks-column-title {
        font-size: calc(12px + 4 * var(--width-ratio));
        font-weight: 500;
        color: #bcc9d0;
        text-transform: uppercase;
      }
    }

    .working-items-checks-row {
      border-bottom: 3px solid #23445712;
      padding: 8px 0px;

      &:last-child {
        border-bottom: none;
      }

      .working-items-checks-title {
        font-size: calc(12px + 4 * var(--width-ratio));
        padding-left: 7px;
        color: #1c3e52;
      }

      .working-items-checks-remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: calc(14px + 4 * var(--width-ratio));
        color: #5b7b8c;
      }

      .working-items-checks-images {
        text-align: center;
      }
    }
  }

  .working-items-checks-groups {
    width: 100%;

    .row {
      padding-right: 39px;
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }

      .working-items-checks-group-name-title-container {
        display: flex;
        align-items: center;
        background-color: #f0f0f0;
        padding: 5px 0px 5px 12px;
        .working-items-checks-group-name-title-icon {
          margin-right: 5px;
          margin-bottom: 2px;
          color: #234457;
        }
        .working-items-checks-group-name-title {
          display: inline-block;
          margin: 0px;
          font-size: 17px;
          color: #1c3e52;
        }
      }

      .working-items-checks-group-name-item-box {
        display: flex;
        align-items: center;
        border-bottom: 3px solid #23445712;
        padding: 5px 0px 5px 12px;
        break-inside: avoid;

        &:last-child {
          border-bottom: none;
        }

        .working-items-checks-group-title {
          color: #1c3e52;
          break-inside: avoid;
        }
      }
    }
  }

  .working-items-checks-no-remarks {
    margin-top: 19px;
    border: 1.5px solid #0e8d1b;
    border-radius: 4px;
    background-color: #e0f4e1;
    padding: 8px 8px 12px 9px !important;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1c3e52;
    width: 100%;
    text-align: center;
  }
}
