.action-buttons-container {
  display: flex;
  justify-content: center;

  &.read-only {
    .action-buttons-cart,
    .action-buttons-contact,
    .action-buttons-dont-fix {
      cursor: default;
      border-color: #d1d1d1;

      &.selected {
        background-color: #d1d1d1;

        span,
        svg {
          color: white;
        }
      }

      span,
      svg {
        color: #d1d1d1;
      }
    }
  }

  .action-buttons-spacer {
    line-height: normal;
    margin-right: calc(8px + 7 * var(--width-ratio));
  }

  .action-buttons-cart,
  .action-buttons-contact,
  .action-buttons-dont-fix,
  .action-buttons-spacer {
    display: inline-block;
    height: calc(27.75px + 9.25 * var(--width-ratio));
    width: calc(54.75px + 18.25 * var(--width-ratio));
    border-radius: 3px;
    cursor: pointer;
    text-align: center;
    padding-top: calc(3px + 3 * var(--width-ratio));
    white-space: nowrap;

    @media (max-width: 1223px) {
      height: 40px;
      width: 70px;
      padding-top: 6px;
    }

    &.selected {
      background-color: #0f39cc;

      span,
      svg {
        color: #fff;
      }
    }

    .action-buttons-cart-plus {
      font-size: calc(11px + 5 * var(--width-ratio));
      line-height: normal;
      display: inline-block;
      margin-right: calc(3.75px + 1.25 * var(--width-ratio));

      @media (max-width: 1223px) {
        font-size: 15px;
        line-height: normal;
      }
    }

    .action-buttons-cart-checked {
      font-size: calc(11px + 5 * var(--width-ratio));
      line-height: normal;
      display: inline-block;
      margin-right: calc(3.75px + 1.25 * var(--width-ratio));

      @media (max-width: 1223px) {
        font-size: 15px;
        line-height: normal;
      }
    }

    .action-buttons-icon {
      font-size: calc(16.5px + 5.5 * var(--width-ratio));
      line-height: normal;
      display: inline-block;

      @media (max-width: 1223px) {
        font-size: 23px;
        line-height: normal;
      }
    }
  }

  .action-buttons-cart {
    color: #56aa0e;
    background-color: #fff;
    border: 2px solid #56aa0e;

    &.selected {
      background-color: #56aa0e;
    }
  }

  .action-buttons-contact {
    color: #0f39cc;
    background-color: #fff;
    border: 2px solid #0f39cc;
    margin-right: calc(8px + 7 * var(--width-ratio));

    &.selected {
      background-color: #0f39cc;
    }
  }

  .action-buttons-dont-fix {
    color: #cc100f;
    background-color: transparent;
    border: 2px solid #cc100f;
    margin-right: calc(8px + 7 * var(--width-ratio));

    &.selected {
      background-color: #cc100f;
    }
  }
}
