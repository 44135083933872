.report-fixed-container.component-container {
  .fixed-heading {
    margin-bottom: 5px;
    break-inside: avoid;

    .fixed-heading-title-container {
      display: flex;
      align-items: center;
      float: left;

      .fixed-heading-icon {
        font-size: calc(24px + 6 * var(--width-ratio));
        margin-right: 5px;
        margin-bottom: 3px;
        color: #234457;
      }

      .fixed-heading-title {
        margin: 0em;
        color: #1c3e52;
        font-weight: 500;
        font-size: calc(24px + 6 * var(--width-ratio));
        display: inline-block;
        vertical-align: middle;
        height: fit-content;
      }
    }

    .fixed-heading-status-container {
      text-align: right;
      float: right;
      margin-top: 2px;

      .fixed-heading-status-item {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 15px;
        }

        .fixed-heading-status-text {
          color: #9cabb4;
          font-size: 12px;
          text-transform: uppercase;
          margin-left: 7px;
          vertical-align: middle;
          height: 33px;
          display: inline-block;
        }
      }
    }
  }

  .fixed-header {
    font-size: calc(12px + 3 * var(--width-ratio));
    font-weight: 500;
    color: #bcc9d0;
    text-transform: uppercase;
    break-inside: avoid;

    .column {
      break-inside: avoid;
    }
  }

  .row.fixed-row {
    padding: 4px 0px !important;
    break-inside: avoid;

    &:last-child {
      border-bottom: none;
    }

    .fixed-title {
      color: #1c3e52;

      .expandable-container {
        margin-right: 20px;
      }
    }

    .fixed-description {
      font-size: calc(14px + 4 * var(--width-ratio));
      color: #5b7b8c;

      .expandable-container {
        margin-right: 20px;
      }
    }

    .fixed-replaced {
      margin-top: 10px;
      padding-right: 20px !important;
      color: #1c3e52;
    }

    .fixed-price {
      font-size: calc(13px + 5 * var(--width-ratio));
      color: #5b7b8c;
    }

    .fixed-arrow {
      font-size: 20px;
    }
  }

  .row.description-row {
    border-bottom: 2px solid #23445712;
    padding: 4px 0px !important;
    color: #5b7b8c;
    margin-top: 10px;
    margin-bottom: 5px;
    break-inside: avoid;
  }
}
