.KioskLabelPicker {
  display: grid;
  grid-template-columns: repeat(5, 141px);
  gap: 34px;
  padding: 0 50px 80px;
  border-radius: 37px;

  &__button.ui.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 141px;
    height: 141px;
    border: none;
    border-radius: 37px;
    font-size: 52px;
    background-color: #ffffff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;

    &:hover {
      background-color: #e0e0e0;
    }

    &.-active {
      background-color: #4caf50;
      color: #fff;
    }

    &:disabled {
      background-color: #ddd;
      cursor: not-allowed;
    }
  }
}
