.image-video-letter {
  color: #5d7c8c;
  border: 1px solid #5d7c8c;
  border-radius: 2px;
  padding: 2px 5px;
  width: fit-content;
  font-family: monospace;
  word-break: break-all;
  min-height: 28px !important;
  margin-right: 10px;
  display: inline-block;

  .image-video-icon {
    float: left;
    height: 19px;
    margin-right: 5px;
    vertical-align: top;
  }
}
