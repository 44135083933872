.KeyLockerFullScreen {
  text-align: center;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-40%);

  .KeyLockerFullIcon {
    width: 66px;
    height: 54px;
    color: #21ba45;
    margin: 0 10px 10px 0;
    vertical-align: middle;
  }

  .KeyLockerFullMessage {
    font-size: 52px;
    line-height: 71px;
    margin-top: 40px;
  }

  .KeyLockerFullCompositeIcon {
    display: inline-block;
    margin-right: 10px;

    svg {
      font-size: 70px;
      color: #1ba753;

      &:first-child {
        font-size: 70px;
        transform: translateY(95px);
      }
    }
  }
}
