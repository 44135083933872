.HelpScreen {
  font-size: 52px;
  line-height: 71px;

  .HelpTitle {
    margin-bottom: 24px;

    .icon {
      font-size: 28px;
      color: white;
      background-color: #1ba753;
      box-shadow: none;
      vertical-align: middle;
      margin-right: 16px;
      margin-bottom: 5px;
    }
  }

  .HelpContent {
    > div {
      margin-bottom: 40px;
    }
  }
}
