.report-dealer-header-container.component-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  break-inside: avoid;

  .report-header-img-container {
    width: 25%;

    .report-header-img {
      max-height: 100px;
      max-width: 100%;
      width: auto;
      display: block;

      @media print {
        width: 100% !important;
      }
    }
  }

  .report-header-info-container {
    width: 70%;
    display: grid;
    grid-template-columns: 55% 40%;
    grid-column-gap: 5%;
    text-align: right;

    & > div:first-child {
      text-align: left;
    }

    .report-header-info-item {
      font-size: 17px;
      color: #a2a8ad;
      margin-bottom: 0.5em;
      word-break: break-word;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
