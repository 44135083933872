.ui.fullscreen.modal {
  position: relative;
}

.ui.grid {
  .column {
    padding: 0 !important;
  }
}

.grid.mainGrid {
  padding: 0;
  margin: 0;

  .mainRow {
    padding-top: 0;
    padding-bottom: 0;

    .column.mainColumn {
      background-color: #f3f5f7;

      .mainContent {
        padding: 1em 2em 2em 2em;
      }
    }

    .component-container {
      border: 2px solid #b1b8bc;
      border-radius: 0.3em;
      background-color: #fff;
      margin: 0.5em 0;
      padding: 1.4rem;

      > div {
        padding: 0;
      }

      > .grid {
        margin: 0;
        width: 100%;
      }
    }
  }
}

@media (max-width: 1223px) {
  .grid.mainGrid {
    padding-bottom: 80px;

    .mainRow {
      min-height: calc(100vh - 80px);
      overflow-y: auto;
      overflow-x: hidden;
      background-color: #f3f5f7;

      .component-container {
        margin: 0.5em 2.5%;
      }
    }

    .column.mainColumn {
      .mainContent {
        padding: 0em;
      }
    }

    .component-container {
      border-width: 1px;
      padding: 8px;
    }
  }
}
