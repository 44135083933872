.simple-car-images-container {
  border: 2px solid rgba(35, 68, 87, 0.4);
  border-radius: 0.3em;
  background-color: #fff;
  margin: 1em 1.4rem 0em 1.4rem;
  box-sizing: border-box;
  padding: 1em;

  h4 {
    margin: 0 0 0.5em 0;
    color: #1c3e52;
    font-weight: 600;
    font-size: calc(24px + 6 * var(--width-ratio));
    display: inline-block;
  }

  .simple-car-images-description {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    color: #5b7b8c;
    font-size: 15px;
    break-inside: avoid;

    .simple-car-images-inner-container {
      width: 50%;

      &.full {
        width: 100%;

        .simple-car-image-wraper {
          width: 48%;
          margin-bottom: 0.5%;
        }
      }

      .simple-car-image-wraper {
        width: 48%;
        margin-bottom: 0.5%;
      }

      .simple-car-image-wraper {
        display: inline-block;
        position: relative;
        width: 96%;
        margin-right: 2%;
        margin-bottom: 1%;
        break-inside: avoid;

        .simple-car-img-label {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          top: 0;
          right: 0;
          width: 35px;
          height: 35px;
          font-size: 18px;
          font-weight: 600;
          color: #234457;
          background-color: rgba(255, 255, 255, 0.8);
          break-inside: avoid;
          break-before: avoid;
          break-after: avoid;
        }

        img {
          width: 100%;
        }
      }
    }

    .simple-car-images-description-text {
      text-align: left;
      width: 48%;
      margin-left: 2%;
      font-size: calc(14px + 4 * var(--width-ratio));
      color: #5b7b8c;
      break-inside: avoid;
    }
  }
}
