.KioskNavigationButton.button {
  color: white;
  font-size: 32px;
  line-height: 49px;
  border-radius: 4px;
  margin: 0;
  background-color: #1dbf5e;

  &:focus {
    background-color: #1dbf5e;
    color: #fff;
  }

  .icon {
    background-color: #1ba753;
  }

  &.back,
  &.cancel {
    color: #717171;
    background-color: #e0e1e2;

    .icon {
      color: #717171;
      background-color: #cecece;
    }
  }

  &.secondary {
    background-color: #6435c9;
  }
}
