.OrderEditCustomerNameScreen {
  margin: 15px 10px 0 0;

  .OrderEditCustomerNameTitle {
    margin-bottom: 10px;

    svg {
      width: 50px;
      height: 50px;
      color: #1ba753;
      margin-right: 7px;
      vertical-align: baseline;
    }
  }

  .OrderEditCustomerName {
    width: 100%;
    height: 110px;
    font-size: 36px;
    line-height: 44px;
    color: #1c3e52;
    resize: none;
    padding: 10px;
    border: 2px solid #dededf;
    border-radius: 4px;
    margin-bottom: 0;
    background-color: white;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #e0e1e2;
      font-weight: 600;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e0e1e2;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e0e1e2;
    }

    &.error {
      border-color: #800a0a;
    }
  }

  .signature-canvas {
    position: relative;
    overflow: hidden;
    width: 100%;
    border: 1px solid #b1b8bc;
    margin-top: 1.75rem;
    padding: 0.825rem;
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: #000000de;
    border-radius: 0.3rem;
    height: 30vh;
    margin-bottom: 30px;

    &.error {
      border-color: #800a0a;
    }

    span {
      position: absolute;
      top: 8px;
      display: block;
      color: #b5b5b5;
      font-weight: 400;
      font-size: 1.2rem;
      user-select: none;
    }

    canvas {
      background: url("/signature-bg.png") no-repeat center center;
    }
  }

  .clear-signature-btn {
    padding: 1.25rem 2.6rem;
    background-color: #1b0505;
    border-radius: 4px;
    color: #fff;
    margin-right: auto;
    margin-top: 2rem;
    font-size: 1.4rem;
  }

  .error-msg {
    color: #800a0a;
  }
}
