@media print {
  * {
    color-adjust: exact;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }
}

@page {
  margin: 30px 15mm;
  size: auto;
}

.report-template .component-container {
  &:not(.borderless-container) {
    border: none !important;
    padding: 1rem;
  }

  &.borderless-container {
    padding: 1rem 1.4rem;
  }
}