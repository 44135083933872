.counter-tablet-container {
  &.ui.segment,
  &.ui.grid {
    margin-top: 10%;
  }

  .header-title {
    font-weight: 300;

    span {
      color: #21ba45;
      font-weight: 600;
    }
  }

  .google-login {
    &:active,
    &:focus,
    &:hover {
      background-color: #fff;
      color: #21ba45;
    }

    width: 70%;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: 3rem;
    background-size: 1.5em;
    border: 1px solid #21ba45;
    color: #21ba45;
  }
}
