.ErrorModal {
  &__header {
    display: flex;
    justify-content: space-between;

    h1 {
      color: #cc100f;
      font-size: 1.8rem;

      @media (max-width: 1223px) {
        font-size: 1.4rem;
      }
    }
  }

  &__content {
    padding: 3.4em;
    font-size: 1.6rem;
    text-align: center;
    margin-top: 22px;

    @media (max-width: 1223px) {
      font-size: 1.4rem;
      padding: 2.4em;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 22px;

    &-reload.ui.button {
      padding: 1rem 6rem;
      color: #fff;
    }
  }
}
