.DeskCheckin {
  color: #1c3e52;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  background-color: #f3f5f7;

  .DeskCheckinTitle {
    display: flex;
    width: 100%;
    align-items: center;
    background-color: #fff;
    height: 64px;
    padding: 0 32px;

    h1 {
      font-size: 24px;
      line-height: 29px;
    }
  }

  .DeskCheckinContent {
    padding: 16px 32px 0 32px;
    height: calc(100vh - 64px - 116px);
    overflow-y: auto;
  }

  .DeskCheckinNavigation {
    padding: 19px 32px 40px 32px;
    height: 116px;

    .ui.button {
      display: inline-block;
      height: 100%;
      margin: 0;
    }

    .DeskCheckinNavigationButton {
      width: 100%;
    }

    .DeskCheckinNavigationLeftButton,
    .DeskCheckinNavigationRightButton {
      width: 50%;
    }

    .DeskCheckinNavigationLeftButton {
      background-color: #fff;

      .navigation-text {
        color: #000;
      }

      .navigation-icon {
        color: #000;
        margin-right: 3px;
      }
    }

    .DeskCheckinNavigationButton,
    .DeskCheckinNavigationRightButton {
      background-color: #1b0505;

      .navigation-text {
        color: #fff;
      }

      .navigation-icon {
        color: #fff;
        margin-left: 3px;
      }
    }
  }
}
