@import url("https://fonts.googleapis.com/css?family=Lato:100,300,400,700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
#main {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  width: 917px;
}
#We_noticed_you_are_using_an_ol {
  position: absolute;
  left: 0px;
  top: 568px;
  overflow: visible;
  width: 917px;
  white-space: nowrap;
  line-height: 36px;
  margin-top: -5.5px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(150, 150, 150, 1);
}
#mainpic {
  position: absolute;
  width: 505px;
  height: 352.5px;
  left: 205px;
  top: 101px;
  overflow: visible;
}
#chromepic {
  position: absolute;
  width: 60px;
  height: 60px;
  left: 296px;
  top: 794px;
  overflow: visible;
}
#firefoxpic {
  position: absolute;
  width: 62px;
  height: 62px;
  left: 419px;
  top: 793px;
  overflow: visible;
}
#edgepic {
  position: absolute;
  width: 70px;
  height: 70px;
  left: 534px;
  top: 788px;
  overflow: visible;
}
#Browser_update_required {
  position: absolute;
  left: 215px;
  top: 485px;
  overflow: visible;
  width: 563px;
  white-space: nowrap;
  line-height: 50px;
  margin-top: -0.5px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 49px;
  color: rgba(59, 59, 59, 1);
}
#Supported_browsers {
  position: absolute;
  left: 350px;
  top: 730px;
  overflow: visible;
  width: 217px;
  white-space: nowrap;
  line-height: 50px;
  margin-top: -13px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  color: rgba(29, 191, 95, 1);
}
#Group_1 {
  position: absolute;
  width: 61px;
  height: 61px;
  left: 136px;
  top: 483px;
  overflow: visible;
}
.Path_1 {
  overflow: visible;
  position: absolute;
  width: 61px;
  height: 61px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#_ {
  position: absolute;
  left: 24px;
  top: 16px;
  overflow: visible;
  width: 13px;
  white-space: nowrap;
  line-height: 36px;
  margin-top: -3px;
  text-align: center;
  font-family: Font Awesome 5 Pro;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: rgba(99, 214, 145, 1);
}
#Chrome {
  position: absolute;
  left: 296px;
  top: 871px;
  overflow: visible;
  width: 61px;
  white-space: nowrap;
  line-height: 42px;
  margin-top: -12.5px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(59, 59, 59, 1);
}
#Firefox {
  position: absolute;
  left: 425px;
  top: 871px;
  overflow: visible;
  width: 53px;
  white-space: nowrap;
  line-height: 42px;
  margin-top: -12.5px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(59, 59, 59, 1);
}
#Microsoft_Edge {
  position: absolute;
  left: 521px;
  top: 871px;
  overflow: visible;
  width: 115px;
  white-space: nowrap;
  line-height: 42px;
  margin-top: -12.5px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  color: rgba(59, 59, 59, 1);
}
