.optional-services-container {
  .optional-services-header {
    margin-bottom: calc(20px + 9 * var(--width-ratio));

    .optional-services-header-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      color: #234457;
    }

    h4 {
      display: inline-block;
      margin: 0 0 0 8px;
      font-size: calc(24px + 6 * var(--width-ratio));
      color: #1c3e52;
    }
  }

  .optional-services-heading {
    margin-bottom: 14px;

    .optional-services-heading-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #bcc9d0;
    }
  }

  .row.optional-services-item {
    border-bottom: 1px solid #e5e9eb;

    &:last-child {
      border-bottom: none;
    }

    &.mobile {
      .optional-services-name,
      .optional-services-price {
        margin-bottom: 0.25em;
      }
    }

    .optional-services-name {
      color: #1c3e52;
      font-size: calc(18px + 2 * var(--width-ratio));
      line-height: calc(22px + 8 * var(--width-ratio));
      word-break: break-word;

      @media (max-width: 1223px) {
        display: block;
        margin-bottom: 10px;
      }
    }

    .optional-services-description {
      color: #5b7b8c;
      font-size: calc(14px + 4 * var(--width-ratio));
    }

    .optional-services-price {
      color: #5c7c8d;
      font-size: calc(17px + 1 * var(--width-ratio));
      line-height: calc(21px + 9 * var(--width-ratio));
      word-break: break-word;

      @media (max-width: 1223px) {
        display: block;
        margin: 18px 0;
      }
    }

    .optional-services-video-icon {
      color: #a8b7cf;
      font-size: 30px;
      cursor: pointer;
    }

    .optional-services-actions-container {
      text-align: center;

      @media (max-width: 1223px) {
        text-align: right;
      }

      .optional-services-action-url {
        margin-top: 7px;
        margin-left: 7px;

        @media (max-width: 1223px) {
          float: left;
        }
      }

      .action-buttons-container {
        @media (max-width: 1223px) {
          margin-right: 18px;
          margin-bottom: 6px;
          justify-content: flex-end;
        }
      }
    }
  }
}

.ui.modal > .modalContent.content {
  background-image: url("/loading.gif");
  background-repeat: no-repeat;
  background-position: center;
}
