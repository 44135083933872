.report-simple-header-info-container {
  border: 2px solid rgba(35, 68, 87, 0.4);
  border-radius: 0.3em;
  background-color: #fff;
  margin: 5px 1.4rem 0px 1.4rem;
  box-sizing: border-box;
  padding: 6px 8px;

  @media (max-width: 1223px) {
    margin: 8px;
  }

  h4 {
    margin: 0 0 5px 0;
    color: #1c3e52;
    font-weight: 600;
    font-size: calc(24px + 6 * var(--width-ratio));
    display: inline-block;
  }

  .simple-header-info-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .simple-header-info-text {
      width: 80%;

      .simple-header-info-column {
        max-width: 50%;
        padding-right: 5%;
        float: left;

        .simple-header-info-text-content-wrapper:not(:last-child) {
          margin-bottom: 6px;
        }

        .simple-header-info-text-content-wrapper {
          .info-text-lable {
            font-size: calc(16px + 4 * var(--width-ratio));
            color: #3333ff;
            font-weight: 600;
          }

          .info-text {
            font-size: calc(13px + 4 * var(--width-ratio));
            color: #5b7b8c;
          }
        }
      }
    }

    .simple-header-info-logo {
      width: 20%;

      img {
        width: 100%;
      }
    }
  }
}
