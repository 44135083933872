.CallForHelpScreen {
  .CallForHelpTitle {
    margin-bottom: 24px;

    .CallForHelpIcon {
      width: 50px;
      height: 50px;
      color: #1ba753;
      vertical-align: middle;
      margin-right: 16px;
    }
  }

  .CallForHelpMessage {
    font-size: 52px;
    line-height: 71px;
  }
}
