.PickUp_Key_WelcomeScreen {
  .WelcomeScreenMessage {
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    width: 100%;
    margin-bottom: 63px;
    margin-top: 122px;
  }

  .WelcomeScreenSteps {
    width: 1278px;
    margin: auto;

    .column:first-child {
      .WelcomeScreenStepContainer {
        .WelcomeScreenStep {
          margin-left: 0px;
        }
      }
    }

    .column:last-child {
      .WelcomeScreenStepContainer {
        .WelcomeScreenStep {
          margin-right: 0px;
        }
      }
    }

    .center.aligned.four.wide.column.WelcomeScreenStepContainer {
      display: flex;
      justify-content: center;

      .WelcomeScreenStep {
        background-color: rgba(27, 167, 83, 0.1);
        border-radius: 37px;
        margin-left: 13px;
        margin-right: 13px;
        width: 280px;
        height: 322px;

        .WelcomeScreenStepNumber {
          width: 64px;
          height: 53px;
          background-color: #1ba753;
          border-radius: 37px;
          color: white;
          margin: 0 auto;
          padding: 6px 0;
          transform: translateY(-22.5px);
          margin-bottom: 50px;
          font-size: 28px;
          line-height: 38px;
        }

        .WelcomeScreenStepSingleIcon {
          font-size: 60px;
          color: #1ba753;
        }

        .WelcomeScreenStepCompositeIcon {
          width: 100%;
          margin-top: -30px;

          svg {
            font-size: 60px;
            color: #1ba753;

            &:first-child {
              font-size: 28px;
            }
          }
        }

        .WelcomeScreenStepTitle {
          height: 165px;
          position: relative;

          p {
            font-size: 28px;
            line-height: 38px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            padding: 0 12px;
          }
        }
      }
    }
  }
}
