.DeskCheckinSignature {
  position: relative;

  .signature-container {
    margin-top: 25px;
    border: 1px solid #b1b8bc;
    border-radius: 2px;
    color: #5c7c8d;

    .signature-header {
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      background-color: #f3f5f7;
      padding: 1rem 1.5rem;
    }

    .signature-content {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      padding: 1rem 1.5rem;

      h2 {
        margin-bottom: 1.5rem;
        font-weight: 400;
      }

      .signature-name {
        .ui.input {
          width: 100%;
          margin-top: 7px;
        }
      }
    }

    .signature-canvas {
      position: relative;
      overflow: hidden;
      width: 100%;
      border: 1px solid #b1b8bc;
      margin-top: 1.75rem;
      padding: 0.825rem;
      border: 1px solid rgba(34, 36, 38, 0.15);
      color: #000000de;
      border-radius: 0.3rem;
      height: 30vh;

      &.error {
        border-color: #800a0a;
      }

      span {
        position: absolute;
        top: 8px;
        display: block;
        color: #b5b5b5;
        font-weight: 400;
        font-size: 1.2rem;
        user-select: none;
      }

      canvas {
        background: url("/signature-bg.png") no-repeat center center;
      }

      .signature-img-wrapper {
        display: flex;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: url("/signature-bg.png") no-repeat center center;

        img {
          align-self: center;
        }
      }
    }

    .clear-signature-btn {
      padding: 1.25rem 2.6rem;
      background-color: #1b0505;
      border-radius: 4px;
      color: #fff;
      margin-right: auto;
      margin-top: 2rem;
      font-size: 1.4rem;
    }

    .error-msg {
      color: #800a0a;
    }
  }
}

.DeskCheckinSignatureKeyboard {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
}
