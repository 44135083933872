.desk-working-items-container.component-container {
  border: 1px solid #b1b8bc;
  border-radius: 2px;
  margin-bottom: 15px;
  padding: 15px 35px;
  background-color: #fff;

  .working-items-heading {
    margin-bottom: 12px;

    .working-items-heading-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 8px;
      color: #234457;

      &.check.circle.outline {
        margin-right: 0.2em;
        margin-top: 0.2em;
      }
    }

    .working-items-heading-title {
      margin: 0em;
      color: #1c3e52;
      font-weight: 500;
      font-size: calc(24px + 6 * var(--width-ratio));
      width: calc(100% - calc(24px + 6 * var(--width-ratio)) - 8px);
      margin-top: -2px;
    }
  }

  .working-items-with-remarks {
    width: 100%;
    .row {
      padding: 0;
    }

    .working-items-row {
      border-bottom: 2px solid #23445712;
      padding: 20px 12px 0 12px !important;

      .working-items-title-container {
        display: flex;
        align-items: center;
        flex-direction: row;

        .working-items-title {
          display: inline-block;
          width: calc(100% - calc(34px + 19 * var(--width-ratio)));
          height: calc(34px + 19 * var(--width-ratio));
          padding-left: calc(12px + 6 * var(--width-ratio));
          margin-top: calc(4px + 6 * var(--width-ratio));
          font-size: calc(18px + 2 * var(--width-ratio));
          color: #1c3e52;
          vertical-align: middle;
        }
      }

      .working-items-remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: calc(14px + 4 * var(--width-ratio));
        color: #5b7b8c;

        margin-top: 10px;

        .expandable-container span.expanded {
          height: 1.5em;
        }
      }

      .working-items-images {
        text-align: left;
        .gallery-button-label {
          margin-top: 15px;
          float: left;
        }
      }
    }
  }

  .working-items-groups {
    .row {
      padding-right: 39px;
      margin-top: 50px;

      &:first-child {
        margin-top: 0;
      }

      .working-items-group-name-title {
        margin-bottom: 17px;
        font-size: 17px;
        color: #1c3e52;
        padding: 4px 0 3px 9px;
        background-color: #caedcd59;
      }

      .working-items-group-name-item-box {
        display: flex;
        align-items: center;
        border-bottom: 2px solid #dbf4dc;
        padding: 3px 0 3px 9px;

        .working-items-group-title {
          font-size: 16px;
          color: #1c3e52;
          margin-bottom: 5px;
          margin-left: 7px;
          margin-top: 3px;
        }
      }
    }
  }

  .working-items-no-remarks {
    margin-top: 19px;
    border: 1.5px solid #0e8d1b;
    border-radius: 4px;
    background-color: #e0f4e1;
    padding: 8px 8px 12px 9px !important;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1c3e52;
    width: 100%;
    text-align: center;
  }
}
