.report-header-checks-container {
  .header-title-container {
    display: flex;
    justify-content: space-between;
    margin: 0;

    .header-title {
      color: #1c3e52;
      font-weight: 700;
      font-size: calc(20px + 14 * var(--width-ratio));
    }

    .header-print-button,
    .header-save-button {
      width: calc(108px + 100 * var(--width-ratio));
      font-size: calc(14px + 6 * var(--width-ratio));
      font-weight: 400;
    }
  }

  .header-info-container.component-container {
    padding: 0 !important;
    margin: 0 !important;
    border-radius: 0px !important;

    .row {
      background-color: #f3f5f7;
      padding: 7px !important;
    }

    .header-info-heading {
      font-size: calc(15px + 9 * var(--width-ratio));
      background-color: #f3f5f7;
      color: #234457;
      font-weight: 500;
    }

    .header-info-content {
      background-color: #fff;

      .header-info-icon {
        font-size: calc(16px + 6 * var(--width-ratio));
        min-width: calc(16px + 6 * var(--width-ratio));
        margin-bottom: 3px;
      }

      .margin-bottom-8 {
        margin-bottom: 8px;
        display: flex;
      }

      .flex-centered {
        display: flex;
        align-items: center;
      }

      .header-info-text {
        font-size: calc(16px + 6 * var(--width-ratio));
        color: #5c7c8d;
        margin-left: 0.3em;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .header-info-reg-num-box {
        width: calc(130px + 25 * var(--width-ratio));
        height: calc(24px + 6 * var(--width-ratio));
        margin-bottom: 8px;
        border: 2px solid #234457;
        border-radius: 0.15em;
        min-width: 130px; // IE fix
        max-width: 155px; // IE fix
        min-height: 24px; // IE fix
        max-height: 30px; // IE fix

        .header-info-reg-country {
          width: 21px;
          height: 100%;
          line-height: 100%;
          display: block;
          float: left;
          text-align: center;
          background-color: #40a7ff;
          padding-top: 20px; // IE fix
          padding-top: calc(3px + 2 * var(--width-ratio));
          position: relative;

          .header-info-reg-country-text {
            color: #fff;
            font-size: 10px;
            bottom: 0px;
            position: absolute;
            width: 100%;
          }
        }

        .header-info-reg-num {
          width: calc(100% - 21px);
          height: 100%;
          line-height: 100%;
          display: block;
          float: right;
          text-align: center;
          padding-top: 4px;
          font-weight: 500;
          font-size: calc(16px + 4 * var(--width-ratio));
        }

        &.BE .header-info-reg-num {
          background-color: #fff;
          color: #ac1713;
        }

        &.NL .header-info-reg-num,
        &.LU .header-info-reg-num {
          background-color: #efe24c;
          color: #234457;
        }

        &.FR .header-info-reg-num {
          background-color: #fff;
          color: #000;
        }
      }

      .header-status-container {
        margin-bottom: 0.5em;
        margin-top: 0.3em;

        .header-status {
          height: 25px;
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }

          .header-status-box {
            width: 25px;
            height: 25px;
            border-radius: 0.3em;
            display: inline-block;
            vertical-align: middle;

            &.all-right {
              background-color: green;
            }

            &.advisable {
              background-color: #ffa500;
            }

            &.neccessary {
              background-color: red;
            }

            svg {
              color: white;
              width: 11px;
              margin: 6px 7px;
            }
          }

          .header-status-text {
            font-size: 14px;
            line-height: 17px;
            text-transform: uppercase;
            margin-left: 0.7em;
            font-weight: 400;
            color: #9cabb4;
          }
        }

        .header-status-action-title {
          background-color: #f3f5f7;
          color: #234457;
          font-size: calc(15px + 9 * var(--width-ratio));
          font-weight: 500;
          margin: 0 -8px 16px -8px;
          padding: 8px;
        }

        .header-status-action-container {
          .header-status-action {
            display: inline-block;
            &:first-child {
              margin-right: 15px;
            }

            .header-status-action-box {
              display: inline-block;
              width: 39px;
              height: 20px;
              padding: 0.1em;
              border: 1px solid #0f39cc;
              border-radius: 0.2em;
              text-align: center;

              svg,
              span {
                color: #0f39cc;
                font-size: 1em;
              }
            }

            .header-status-action-text {
              font-size: 14px;
              line-height: 17px;
              color: #9cabb4;
              margin-left: 0.5em;
              font-weight: 300;
              margin-top: 0.1em;
            }
          }
        }
      }
    }

    .header-footer {
      padding: 7px 14px !important;
      background-color: #fff;
      border-top: 1px solid #b1b8bc;

      .column.header-footer-column {
        width: auto !important;
        margin-right: 65px;

        &:last-child {
          margin-right: 0px;
        }

        .header-footer-label {
          font-size: calc(13px + 7 * var(--width-ratio));
          font-weight: 500;
          color: #234457;
        }

        .header-footer-data {
          font-size: calc(13px + 7 * var(--width-ratio));
          color: #5b7b8c;
        }

        .header-footer-date {
          font-size: calc(13px + 7 * var(--width-ratio));
          color: #5b7b8c;
        }
      }
    }
  }
}
