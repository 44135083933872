.DeskCheckinThankYouScreen {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  .ThankYouHeader {
    font-size: 38px;
  }

  .ThankYouIcon {
    width: 60px;
    height: 60px;
    color: #1ba753;
    margin: 0 10px 10px 0;
    vertical-align: middle;
  }

  .ThankYouMessage {
    font-size: 35px;
    line-height: 71px;
  }
}
