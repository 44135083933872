.fixed-container.component-container {
  @media (max-width: 1223px) {
    padding: 20px 12px !important;
  }

  .fixed-heading {
    margin-bottom: 20px;

    .fixed-heading-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 8px;
      color: #234457;
    }

    .fixed-heading-title {
      margin: 0em;
      color: #1c3e52;
      font-weight: 500;
      font-size: calc(24px + 6 * var(--width-ratio));
      display: inline-block;
      margin-top: calc(-2px + -2 * var(--width-ratio));
    }

    .fixed-heading-status-container {
      text-align: right;

      .fixed-heading-status-item {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 18px;
        }

        .fixed-heading-status-text {
          height: 33px;
          display: inline-block;
          vertical-align: middle;
          color: #9cabb4;
          font-size: 14px;
          text-transform: uppercase;
          margin-left: 7px;
        }
      }
    }
  }

  .fixed-header {
    font-size: calc(13px + 3 * var(--width-ratio));
    font-weight: 500;
    color: #bcc9d0;
    text-transform: uppercase;
  }

  .row.fixed-row {
    border-bottom: 2px solid #23445712;
    padding: 8px 0px !important;

    @media (max-width: 1223px) {
      padding: 20px 12px 5px 12px !important;
    }

    @media (min-width: 1224px) {
      &:last-child {
        border-bottom: none;
      }
    }

    .fixed-title {
      font-size: calc(18px + 2 * var(--width-ratio));
      color: #1c3e52;

      .expandable-container {
        margin-right: 20px;
      }
    }

    .fixed-description {
      font-size: calc(14px + 4 * var(--width-ratio));
      color: #5b7b8c;

      .expandable-container {
        margin-right: 20px;
      }

      @media (max-width: 1223px) {
        margin-top: 10px;
        margin-bottom: 20px;

        .expandable-container {
          margin-right: 0;
        }
      }
    }

    .fixed-replaced {
      margin-top: 10px;
      padding-right: 20px !important;
      color: #6a8796;

      svg {
        font-size: calc(18px + 3 * var(--width-ratio));
        margin-right: 6px;
      }
    }

    .fixed-price {
      font-size: calc(13px + 5 * var(--width-ratio));
      color: #5b7b8c;

      @media (max-width: 1223px) {
        display: block;
        margin: 17px 0;
        font-size: 17px;
      }
    }

    .fixed-arrow {
      font-size: 20px;
      margin-bottom: 15px;
      margin-right: 14px;
      margin-left: 14px;
      vertical-align: middle;

      @media (max-width: 1223px) {
        font-size: 24px;
        margin: 4px 8px 0 8px;
      }
    }

    .margin-0 {
      margin: 0 !important;
    }

    .fixed-status-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
  }
}
