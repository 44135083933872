.ConfirmAuthenticationScreen {
  color: #1c3e52;

  .ConfirmAuthenticationScreenHeader {
    margin-bottom: 30px;
    .ConfirmAuthenticationScreenTitleIcon {
      font-size: 60px;
      margin-right: 10px;
      color: #1ba753;
    }

    .ConfirmAuthenticationScreenHeaderTitle {
      display: inline-block;
      margin-left: 10px;
    }
  }

  .ConfirmAuthenticationScreenSelection {
    width: 100%;
    margin-top: 80px;
    text-align: center;

    .ConfirmAuthenticationScreenButtons {
      display: flex;

      .button {
        font-size: 50px;
        width: 100%;
        color: #1c3e52;

        &.active {
          background-color: #1dbf5e;
          color: #fff;
        }
      }
    }
  }

  .ConfirmAuthenticationScreenMessage {
    .ConfirmAuthenticationScreenMethod-container {
      display: flex;
    }

    .ConfirmAuthenticationScreenMethod {
      background-color: rgba(224, 225, 226, 0.2);
      margin-right: 12.5px;
      margin-top: 15px;
      padding: 10px;
      border-radius: 4px;
      width: 100%;

      & div {
        display: inline-block;
        padding-right: 30px;
      }
    }

    .ConfirmAuthenticationScreenEmailExplanation {
      font-size: 52px;
      line-height: 71px;
      margin-top: 40px;
    }

    & > div {
      margin-bottom: 5px;
      font-size: 52px;
      line-height: 71px;
    }

    & svg {
      font-size: 36px;
      margin-right: 10px;
    }
  }
}
