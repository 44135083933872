.expandable-container {
  margin-top: calc(4px + 5 * var(--width-ratio));

  span {
    width: calc(100% - 26px);
    display: inline-block;
    vertical-align: middle;
    color: #1c3e52;
    white-space: pre-line;

    &.collapsed {
      height: 1.3em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &.desk-checkin-expandable.expanded {
      height: 100% !important;
    }

    &.desk-checkin-optional-item-title {
      font-size: calc(18px + 2 * var(--width-ratio)) !important;
    }
  }

  svg {
    font-size: 16px;
    margin-left: 10px;
    display: inline-block;
  }
}
