.EnterPinScreen {
  .PinCodeInput {
    float: left;

    .KeyLockerTitle {
      margin-bottom: 23px;
    }

    .PinCodeMessage {
      font-size: 40px;
      line-height: 55px;
    }

    .PinCode {
      display: inline-block;
      border-radius: 4px;
      background-color: rgba(224, 225, 226, 0.2);
      padding: 50px 54px;
      font-size: 130px;
      text-align: center;
      line-height: 140px;
      margin-bottom: 36px;

      div {
        display: inline-block;
        width: 140px;
        height: 140px;
        vertical-align: top;
        color: #1c3e52;
        margin-right: 16px;
        border-bottom: 4px solid #1c3e52;

        &:nth-child(3) {
          margin-right: 45px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .NumPad2 {
    float: right;
  }
}
