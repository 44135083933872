.desk-checkin-scheduled-services-outter-container {
  border: 1px solid #b1b8bc;
  border-radius: 2px;
  margin-bottom: 15px;
  padding: 15px 35px;
  background-color: #fff;

  .desk-checkin-scheduled-services-container {
    background-color: #fff;

    .desk-checkin-scheduled-services-header {
      vertical-align: middle;

      h4 {
        display: inline-block;
        margin: 0px 0px 0px 5px;
        font-size: 28px;
        line-height: 32px;
        font-weight: 600;
      }

      .desk-checkin-scheduled-services-header-icon {
        display: inline-block;
        width: 26px;
        height: 28px;
      }
    }

    .desk-checkin-scheduled-services-heading {
      .desk-checkin-scheduled-services-heading-title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 500;
        color: #bcc9d0;
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  .row.desk-checkin-scheduled-services-item {
    border-bottom: 1px solid #e5e9eb;

    &:last-child {
      border-bottom: none;
    }

    .desk-checkin-scheduled-services-name-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .icon {
        cursor: pointer;
      }
    }

    .desk-checkin-scheduled-services-name {
      color: #1c3e52;
      font-size: 18px;
      line-height: 22px;
      word-break: break-word;
      font-weight: 500;
    }

    .desk-checkin-scheduled-services-remark {
      color: #5c7c8d;
      font-size: 18px;
      line-height: 22px;
      word-break: break-word;
      padding-top: 10px;
    }

    .desk-checkin-scheduled-services-price {
      color: #5c7c8d;
      font-size: 18px;
      line-height: 22px;
      text-align: left;
    }
  }
}

.ui.small.modal.scheduled-services-elements-modal-desk-checkin {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .scheduled-services-elements-title {
      font-size: 18px;
      margin-left: 4px;
    }

    svg {
      color: #767676;
      cursor: pointer;
    }
  }

  tr {
    width: 100%;
    td {
      width: 100%;
      display: flex;
      align-items: center;
    }
  }

  .intervention-element-description {
    margin-left: 10px;
  }

  .intervention-element-arrow-to-right {
    width: 32px;
    height: 32px;
    text-align: center;
    margin-right: 10px;
  }
}
