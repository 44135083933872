.mainContent {
  .simple-header-container {
    border: 2px solid rgba(35, 68, 87, 0.4);
    border-radius: 0.3em;
    background-color: #fff;
    margin: 0px 1.4rem;

    @media (max-width: 1223px) {
      margin: 0px 8px;
    }

    h1 {
      color: #1c3e52;
      font-weight: 700;
      font-size: calc(20px + 14 * var(--width-ratio));
      padding: 0.5em 0em;
      text-align: center;
    }
  }

  .simple-footer {
    color: #1c3e52;
    font-weight: 700;
    font-size: calc(20px + 14 * var(--width-ratio));
    padding: 0.5em 0em;
    text-align: center;
    margin-top: 20px;
  }
}
