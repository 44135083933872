.custom-remarks-container {
  .custom-remarks-error-msg {
    color: red;
    padding-top: 0.5em;
  }

  .custom-remarks-header {
    width: 100%;
    margin-bottom: 20px;

    .custom-remarks-header-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      color: #234457;
    }

    h4 {
      display: inline-block;
      margin: 0 0 0 8px;
      font-size: calc(24px + 6 * var(--width-ratio));
      color: #1c3e52;
    }
  }

  .add-remark-title {
    font-size: calc(13px + 3 * var(--width-ratio));
    font-weight: 400;
    color: #1c3e52;
  }

  .add-remark-label {
    font-size: 13px;
    color: #5c7c8d;
    margin-bottom: 0.3em;
  }

  .add-remark-title-field {
    width: 70%;
  }

  .add-remark-description-field {
    width: 90%;
    min-height: 5em;
  }

  .add-remark-upload-files-container {
    display: flex;
    justify-content: space-between;
    border: 2px dashed #e2e2e2;
    border-radius: 6px;
    padding: 1em;
    height: 100%;

    .add-remark-upload-file-section-container {
      &.full {
        width: 100%;
        border-right: none;
      }

      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      border-right: 1px solid #e2e2e2;
      padding-right: 20px;

      .add-remark-upload-file-icon-container {
        text-align: center;
        margin-bottom: 1em;

        .add-remark-upload-file-icon {
          font-size: 50px;
          color: #e2e2e2;
        }
      }

      .add-remark-upload-file-text-container {
        text-align: center;
        margin-bottom: 1em;

        .add-remark-upload-file-text {
          font-size: calc(11px + 4 * var(--width-ratio));
        }
      }
    }

    .add-remark-uploaded-files-container {
      width: 50%;
      margin: auto;
      height: 80%;
      vertical-align: top;
      padding-left: 20px;

      h5 {
        color: #25465a;
        font-weight: 900;
        font-size: 16px;
      }

      p {
        color: #25465a;
        font-size: 11px;
      }

      .add-remark-uploaded-files-item-container {
        display: flex;
        justify-content: space-between;
        margin-left: 0.5em;

        .add-remark-uploaded-files-file-container {
          display: flex;
          align-items: baseline;

          .add-remark-uploaded-files-image-icon {
            margin-right: 0.3em;
            color: #5b7b8c;
            font-size: 13px;
          }

          .add-remark-uploaded-files-attachement-name {
            word-break: break-word;
            color: #25465a;
            margin-bottom: 15px;
          }
        }

        .add-remark-uploaded-files-icon {
          cursor: pointer;
          color: #25465a;
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }
  }
}

.custom-remarks-mobile-heading {
  color: #5c7c8d;
  font-size: 16px;
  font-weight: 500;
  padding-left: 1em;
}

.add-remark-container {
  display: flex;
  align-items: center;
  cursor: pointer;

  .add-remark-plus-sign {
    font-size: 1.3em;
    font-weight: 400;
    color: #1c3e52;
  }

  .add-remark-text {
    margin-left: 0.35em;
    color: #1c3e52;
  }
}
