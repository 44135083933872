.desk-ScheduledServicesScreen {
  height: 100%;

  .grid {
    margin: 0px;
  }
}

.total-amount-agreements-container {
  background-color: #fff;
  border: 1px solid #b1b8bc;
  border-radius: 2px;
}
