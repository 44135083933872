.bottom-vat-container.component-container {
  border: none !important;

  .bottom-vat-inner-container {
    border-top: 2px solid #d9d9d9 !important;
    width: 100%;

    .prices_text {
      display: inline-block;
      color: #5b7b8c;
      font-size: calc(11px + 6 * var(--width-ratio));
      margin-top: 8px;
    }

    .vat {
      display: inline-block;
      color: #5b7b8c;
      font-size: calc(10px + 6 * var(--width-ratio));
      font-weight: 600;
      margin-left: 5px;
    }
  }
}
