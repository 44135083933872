.v2-scheduled-services-container {
  .scheduled-services-header {
    width: 100%;

    .scheduled-services-header-title {
      float: left;

      .scheduled-services-header-icon {
        font-size: calc(24px + 6 * var(--width-ratio));
        color: #234457;
      }

      h4 {
        display: inline-block;
        margin: 0 0 0 8px;
        font-size: calc(24px + 6 * var(--width-ratio));
        color: #1c3e52;
      }
    }

    .scheduled-services-row-remark {
      @media (max-width: 1223px) {
        font-size: calc(20px + 3 * var(--width-ratio));
      }
    }

    .scheduled-services-header-button {
      float: right;
      cursor: pointer;
      user-select: none;
      margin-top: 5px;
      margin-bottom: 15px;

      .scheduled-services-header-button-title {
        font-size: calc(18px + 3 * var(--width-ratio));
        color: #1d3e51;
      }

      .scheduled-services-header-button-icon {
        font-size: calc(15px + 3 * var(--width-ratio));
        color: #9cabb4;
        margin: 0 0 0 0.45em;
      }
    }
  }

  .scheduled-services-heading {
    margin-bottom: 19px;
    margin-top: calc(20px + 9 * var(--width-ratio));

    .scheduled-services-heading-title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #1d3e51;
    }
  }

  .row.scheduled-services-item {
    width: 100%;
    border-radius: 4px;
    margin: 0 0 8px;
    min-height: 76px;
    background-color: #fff;
    padding: 18px 15px !important;
    border: 1px solid #707070;
    justify-content: space-between;
    align-items: center;

    &:last-child {
      border: 1px solid #707070;
    }

    .scheduled-services-price-gallery.approved-intervention {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    &.highlight {
      border: 1.5px solid red;
      background-color: #ffe5e5;

      .action-buttons-container .action-buttons-cart,
      .action-buttons-container .action-buttons-contact {
        &:not(.selected) {
          background-color: #ffe5e5;
        }
      }
    }

    .scheduled-services-row-title {
      display: flex;
      align-items: center;
      padding-right: 20px;
      font-size: calc(18px + 2 * var(--width-ratio));

      .condition-icon {
        margin-right: calc(12px + 6 * var(--width-ratio));
        flex: none;
      }

      .expandable-container {
        display: contents;

        svg {
          margin-top: calc(6px + 6 * var(--width-ratio));
        }
      }

      @media (max-width: 1223px) {
        font-size: calc(20px + 3 * var(--width-ratio));
      }
    }

    .scheduled-services-name-container {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
    }

    .scheduled-services-name {
      color: #1c3e52;
      font-size: calc(18px + 2 * var(--width-ratio));
      line-height: calc(22px + 8 * var(--width-ratio));
      word-break: break-word;
    }

    .scheduled-services-remark {
      color: #5c7c8d;
      font-size: calc(14px + 4 * var(--width-ratio));
    }

    .scheduled-services-price {
      color: #5c7c8d;
      font-size: 18px;
      font-weight: 700;

      &.mobile {
        text-align: right;
      }
    }

    .icon {
      cursor: pointer;
    }

    .scheduled-services-price-gallery {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      .gallery-button-label {
        margin-left: 20px;
      }

      @media (max-width: 1223px) {
        font-size: calc(20px + 3 * var(--width-ratio));
      }
    }

    .actions-buttons-container {
      display: flex;
      justify-content: flex-end;

      @media (max-width: 1223px) {
        margin-top: 10px;
      }
    }
  }
}

.ui.modal.scheduled-services-elements-modal {
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .scheduled-services-elements-title {
      font-size: calc(24px + 3 * var(--width-ratio));
      margin-left: 4px;
    }

    svg {
      color: #767676;
      cursor: pointer;
    }
  }

  tr {
    width: 100%;
    td {
      width: 100%;
      display: flex;
      align-items: center;
      font-size: calc(15px + 6 * var(--width-ratio));

      svg {
        font-size: calc(15px + 3 * var(--width-ratio));
      }
    }
  }

  .intervention-element-description {
    margin-left: 10px;
  }

  .intervention-element-arrow-to-right {
    width: 32px;
    height: 32px;
    text-align: center;
    margin-right: 10px;
  }
}

@media (max-width: 1223px) {
  .scheduled-services-container.grid {
    margin: 0.5em 2.5%;

    .scheduled-services-header {
      display: flex;
      align-items: center;

      h4 {
        margin-top: 0em;
        margin-left: 0.3em;
        font-size: 20px;
      }

      .scheduled-services-header-icon {
        font-size: 20px;
      }
    }

    .row.scheduled-services-item {
      .scheduled-services-remark {
        margin-top: 10px;
        display: block;
      }
    }
  }
}
