.OrderEditCustomerNameScreen {
  .OrderEditCustomerNameTitle {
    margin-bottom: 10px;

    svg {
      width: 50px;
      height: 50px;
      color: #1ba753;
      margin-right: 7px;
      vertical-align: baseline;
    }
  }

  .OrderEditCustomerName {
    width: 100%;
    height: 250px;
    font-size: 36px;
    line-height: 44px;
    color: #1c3e52;
    resize: none;
    padding: 10px;
    border: 2px solid #dededf;
    margin-bottom: 0;
    background-color: white;

    &::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: #e0e1e2;
      font-weight: 600;
      opacity: 1; /* Firefox */
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #e0e1e2;
    }

    &::-ms-input-placeholder {
      /* Microsoft Edge */
      color: #e0e1e2;
    }
  }
}
