.error-modal-container {
  color: #000;

  .error-modal-header.header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      color: #ffba39;
    }

    div {
      color: #767676;
    }

    span {
      color: #000;
      font-size: 18px;
      margin-left: 5px;
    }

    .close-icon {
      cursor: pointer;
      color: #767676;
    }
  }

  .error-modal-content.content {
    text-align: center;
    padding: 70px 0px;
    color: #000000cc;
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
  }

  .error-modal-actions {
    button.ui.basic.button {
      color: #000 !important;
      font-weight: 600;
    }
  }
}
