.images-container {
  margin-top: 1em;
  padding: 0px 22px !important;

  @media (max-width: 1223px) {
    padding: 0px 8px !important;
  }

  .image-container {
    width: 25%;
    display: inline-block;
    position: relative;
    break-inside: avoid;
    padding: 0 0.25em;

    .img-label {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      right: 0;
      width: 60px;
      height: 35px;
      color: #234457;
      background-color: rgba(255, 255, 255, 0.8);
      break-inside: avoid;
      break-before: avoid;
      break-after: avoid;

      .img-icon-wrapper {
        height: 27px;
        color: #5d7c8c;
        border: 1px solid #5d7c8c;
        border-radius: 2px;
        padding: 2px 5px;
        width: fit-content;
        font-family: monospace;

        .image-video-icon {
          height: 19px;
          margin-right: 5px;
        }

        .report-car-images-letter {
          font-weight: bold;
          line-height: 19px;
          display: inline-block;
          vertical-align: top;
        }
      }
    }

    .image-style {
      width: 100%;
      break-inside: avoid;
      break-before: avoid;
      break-after: avoid;
    }
  }
}
