.tyre-overview-container {
  .tyre-overview-heading-container {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    font-size: calc(24px + 6 * var(--width-ratio));

    .tyre-overview-heading-icon {
      color: #1c3e52;
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 5px;
    }

    .tyre-overview-heading-title {
      color: #1c3e52;
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-top: 0px;
      font-weight: 500;
    }
  }

  .tyre-overview-sub-heading {
    padding: 5px 0px 15px 15px;
    display: flex;
    align-self: center;
    align-items: center;

    .tyre-season-icon {
      margin-left: 10px;
      font-size: calc(22px + 6 * var(--width-ratio));
    }

    .tyre-overview-sub-heading-icon {
      margin-right: 10px;
      font-size: calc(20px + 6 * var(--width-ratio));
      color: #1c3e52;
    }

    .tyre-overview-sub-heading-title {
      font-size: calc(20px + 6 * var(--width-ratio));
      font-weight: 600;
      color: #1c3e52;
    }
  }

  .tyre-overview-section-heading-container {
    background-color: #f5f6f8;
    text-align: center;
    padding: 2px 0px;

    .tyre-overview-section-heading-title {
      color: #1c3e52;
      margin-right: 10px;
    }
  }

  .tyre-overview-section-content-container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 0px 15px;
    .tyre-overview-section-inner-container {
      display: inline-block;
      padding: 0 5px 0 0;

      &.right-tyre {
        text-align: right;
        padding: 0 0 0 5px;

        .tyre-overview-section {
          .right-profile {
            margin-right: 10px;
          }
        }
      }

      .tyre-overview-manufacturer-container {
        margin-bottom: 20px;
        .tyre-overview-manufacturer-inner {
          font-size: calc(11px + 6 * var(--width-ratio));
          color: #5b7b8c;
        }
      }

      .tyre-overview-section {
        margin-bottom: 10px;

        .tyre-overview-profile-text {
          color: #5b7b8c;
          margin-left: 10px;
        }

        .tyre-overview-section-box {
          display: inline-block;
          width: 40px;
          height: 40px;
          border: 2px solid;
          border-radius: 50%;
          text-align: center;
          line-height: 36px;
          font-weight: 600;
          font-size: 20px;
        }

        .orange-status {
          background-color: #fbdfca;
          border-color: #eb7a1f;
          color: #eb7a1f;
        }

        .green-status {
          background-color: #e5ffe8;
          border-color: #118b1f;
          color: #118b1f;
        }

        .red-status {
          background-color: #fbd1d1;
          border-color: #eb1f1f;
          color: #eb1f1f;
        }
      }
    }
  }
}
