.KioskSelectLabel-step-one {
  display: flex;
  width: 228px;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  svg.fa-tag {
    color: #21ba45;
  }

  span.plus {
    font-size: 80px;
    font-weight: bold;
    color: #21ba45;
  }

  svg.fa-key {
    color: #1e3050;
  }
}

.ui.modal.KioskLabelErrorModal {
  border-radius: 37px;
  height: 394px;
  text-align: center;
  overflow: hidden;

  i.close.icon {
    font-size: 40px;
    top: 40px;
    right: 59px;
    color: #000000;
  }

  .content {
    padding: 0 55px;
  }

  h1 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 72px;
    line-height: 87px;
    margin-top: 68px;

    .fa-exclamation-circle {
      font-size: 50px;
      margin-right: 24px;
    }
  }

  p {
    font-size: 52px;
    line-height: 63px;
    color: #1c3e52;
    margin-top: 63px;
    font-weight: 600;
  }
}
