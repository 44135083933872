.report-total-amount-container {
  text-align: right;
  background-color: #f3f5f7;
  font-size: calc(17px + 4 * var(--width-ratio));
  padding: 5px;
  margin: 0px 20px;
  color: #1c3e52;
  font-weight: 600;
  break-inside: avoid;

  @media (max-width: 1223px) {
    margin: 0px 8px;
  }
}
