.report-not-worked-on-items-container.component-container {
  .not-worked-on-items-heading {
    margin-bottom: 5px;
    break-inside: avoid;

    .not-worked-on-items-heading-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 8px;
      color: #234457;

      &.check.circle.outline {
        margin-right: 0.2em;
        margin-top: 0.2em;
      }
    }

    .not-worked-on-items-heading-title {
      margin: 0em;
      color: #1c3e52;
      font-weight: 500;
      font-size: calc(24px + 6 * var(--width-ratio));
      width: calc(100% - calc(24px + 6 * var(--width-ratio)) - 8px);
      vertical-align: middle;
    }
  }

  .not-worked-on-items-with-remarks {
    margin-bottom: 10px !important;

    .row {
      padding: 0;
      break-inside: avoid;

      .not-worked-on-items-column-title {
        font-size: calc(12px + 4 * var(--width-ratio));
        font-weight: 500;
        color: #bcc9d0;
        text-transform: uppercase;
      }
    }

    .not-worked-on-items-row {
      border-bottom: 2px solid #23445712;
      padding: 8px 0px;

      &:last-child {
        border-bottom: none;
      }

      .not-worked-on-items-title {
        display: inline-block;
        width: calc(100% - calc(26px + 14 * var(--width-ratio)));
        padding-left: 7px;
        color: #1c3e52;
      }

      .not-worked-on-items-remarks {
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: calc(14px + 4 * var(--width-ratio));
        color: #5b7b8c;
      }

      .not-worked-on-items-images {
        text-align: center;
      }
    }
  }

  .not-worked-on-items-groups {
    width: 100%;

    .row {
      padding-right: 39px;
      margin-top: 15px;

      &:first-child {
        margin-top: 0;
      }

      .not-worked-on-items-group-name-title {
        margin-bottom: 5px;
        font-size: 14px;
        color: #1c3e52;
        padding: 4px 0 3px 9px;
        background-color: #f2f2f2;
      }

      .not-worked-on-items-group-name-item-box {
        display: flex;
        align-items: center;
        border-bottom: 2px solid #f2f2f2;
        padding: 3px 0 3px 9px;
        break-inside: avoid;

        .not-worked-on-items-group-title {
          color: #1c3e52;
          margin-left: 7px;
          break-inside: avoid;
        }
      }
    }
  }

  .not-worked-on-items-no-remarks {
    margin-top: 19px;
    border: 1.5px solid #1c3e52;
    border-radius: 4px;
    background-color: #e0f4e1;
    padding: 8px 8px 12px 9px !important;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    color: #1c3e52;
    width: 100%;
    text-align: center;
  }
}
