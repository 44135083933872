.ThankYouScreen {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  .ThankYouIcon {
    width: 50px;
    height: 50px;
    color: #1ba753;
    margin: 0 10px 10px 0;
    vertical-align: middle;
  }

  .ThankYouMessage {
    font-size: 52px;
    line-height: 71px;
    margin-top: 40px;
  }
}
