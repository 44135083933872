.report-not-fixed-check-container.component-container {
  .not-fixed-heading {
    break-inside: avoid;
    background-color: #f7e4e3;
    margin-bottom: 0 !important;
    padding: 0 !important;

    .not-fixed-heading-title-container {
      padding: 10px;
      font-size: 24px;

      .not-fixed-heading-title-sub-container {
        float: left;
        margin-bottom: 7px;

        .not-fixed-heading-icon {
          font-size: calc(24px + 6 * var(--width-ratio));
          margin-right: 8px;
          color: #234457;
          margin-top: 2.5px;
        }

        .not-fixed-heading-title {
          margin: 0em;
          color: #1c3e52;
          font-weight: 500;
          font-size: calc(24px + 6 * var(--width-ratio));
          display: inline-block;
          margin-top: calc(-2px + -2 * var(--width-ratio));
        }
      }

      .not-fixed-legend-container {
        float: right;
        .not-fixed-heading-sub-status-item {
          display: inline-flex;
          align-items: center;

          &:not(:last-child) {
            margin-right: 20px;
          }

          .not-fixed-heading-sub-status-text {
            display: inline-block;
            vertical-align: middle;
            color: #9cabb4;
            font-size: 12px;
            text-transform: uppercase;
            margin-left: 7px;
          }
        }
      }
    }

    .not-fixed-heading-status-container {
      text-align: right;
      float: right;
      margin-top: 2px;

      .not-fixed-heading-status-item {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 15px;
        }

        .not-fixed-heading-status-text {
          color: #9cabb4;
          font-size: 12px;
          text-transform: uppercase;
          margin-left: 7px;
        }
      }
    }
  }

  .not-fixed-heading-sub {
    break-inside: avoid;
    background-color: #f5f6f6;
    color: #214557;
    margin-bottom: 10px;
    padding: 0 !important;

    .not-fixed-heading-sub-text {
      font-weight: 400;
      padding: 10px 12px 0px 12px;
      font-size: calc(13px + 6 * var(--width-ratio));
      padding-bottom: 10px;
      h2 {
        font-weight: 500;
        font-size: 23px;
      }
    }

    .not-fixed-heading-sub-status-container {
      padding: 15px 12px 10px 12px;
    }
  }

  .not-fixed-header {
    font-size: calc(12px + 4 * var(--width-ratio));
    font-weight: 500;
    color: #bcc9d0;
    text-transform: uppercase;
    break-inside: avoid;

    .column {
      break-inside: avoid;
    }
  }

  .row.not-fixed-row {
    border-bottom: 2px solid #23445712;
    padding: 4px 12px !important;
    break-inside: avoid;

    &:last-child {
      border-bottom: none;
    }

    .not-fixed-checks-title {
      color: #234457;
      font-weight: 600;
      margin-right: 10px;
      font-size: 17.5px;
      display: inline;

      .tyre-description {
        margin-right: 10px;
      }
    }

    .not-fixed-checks-replacement-title {
      color: #234457;
      font-weight: 600;
      margin-right: 10px;
    }

    .tyre-remark {
      font-size: calc(13px + 4 * var(--width-ratio));
      font-weight: 500;
      color: #5b7b8c;
      margin: 5px 0;
    }

    .not-fixed-checks-profile-desc-container {
      margin: 4px 0;
    }

    .not-fixed-checks-description {
      margin-top: 4px;
      margin-bottom: 4px;
      margin-right: 15px;
      font-size: calc(13px + 4 * var(--width-ratio));
      font-weight: 500;
      color: #5b7b8c;
    }

    .not-fixed-checks-tyre-description {
      margin-top: 4px;
      margin-right: 15px;
      margin-left: 7px;
      font-size: calc(13px + 4 * var(--width-ratio));
      font-weight: 500;
      color: #5b7b8c;
    }

    .not-fixed-checks-tyre-manufacturer {
      margin-top: 4px;
      margin-left: 12px;
      font-size: calc(13px + 4 * var(--width-ratio));
      font-weight: 500;
      color: #5b7b8c;
    }

    .not-fixed-checks-profile {
      margin-left: 0;
      margin-right: 10px;
      font-size: calc(13px + 4 * var(--width-ratio));
      font-weight: 500;
      color: #5b7b8c;
    }

    .not-fixed-checks-price {
      align-self: flex-start !important;
    }

    .expandable-container {
      padding-right: 20px;
    }

    .not-fixed-title {
      color: #1c3e52;
    }

    .not-fixed-description {
      margin-top: 5px;
      color: #5b7b8c;
      display: block;

      .expandable-container {
        margin-right: 20px;
      }
    }

    .not-fixed-price {
      color: #5b7b8c;
    }

    .not-fixed-checks-status {
      text-align: right;
      align-self: flex-start !important;
    }
  }
}
