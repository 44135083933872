.not-fixed-container.component-container {
  @media (max-width: 1223px) {
    padding: 20px 12px !important;
  }

  .not-fixed-heading {
    margin-bottom: 20px;

    .not-fixed-heading-icon {
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 8px;
      color: #234457;
    }

    .not-fixed-heading-title {
      margin: 0em;
      color: #1c3e52;
      font-weight: 500;
      font-size: calc(24px + 6 * var(--width-ratio));
      display: inline-block;
      margin-top: calc(-2px + -2 * var(--width-ratio));
    }

    .not-fixed-heading-status-container {
      text-align: right;

      .not-fixed-heading-status-item {
        display: inline-block;
        &:not(:last-child) {
          margin-right: 18px;
        }

        .not-fixed-heading-status-text {
          height: 33px;
          display: inline-block;
          vertical-align: middle;
          color: #9cabb4;
          font-size: 14px;
          text-transform: uppercase;
          margin-left: 7px;
        }
      }
    }
  }

  .not-fixed-header {
    font-size: 16px;
    font-weight: 500;
    color: #bcc9d0;
    text-transform: uppercase;
  }

  .row.not-fixed-row {
    border-bottom: 2px solid #23445712;
    padding: 8px 0px !important;

    @media (max-width: 1223px) {
      padding: 20px 12px 5px 12px !important;
    }

    @media (min-width: 1224px) {
      &:last-child {
        border-bottom: none;
      }

      .expandable-container {
        padding-right: 20px;
      }
    }

    .not-fixed-title {
      font-size: calc(18px + 2 * var(--width-ratio));
      color: #1c3e52;
    }

    .not-fixed-description {
      font-size: calc(14px + 4 * var(--width-ratio));
      color: #5b7b8c;

      .expandable-container {
        margin-right: 20px;
      }

      @media (max-width: 1223px) {
        margin-top: 10px;
        margin-bottom: 20px;

        .expandable-container {
          margin-right: 0;
        }
      }
    }

    .not-fixed-price {
      font-size: calc(13px + 5 * var(--width-ratio));
      color: #5b7b8c;

      @media (max-width: 1223px) {
        display: block;
        margin: 17px 0;
        font-size: 17px;
      }
    }

    .not-fixed-status {
      text-align: center;

      @media (max-width: 1223px) {
        text-align: right;
      }
    }
  }
}
