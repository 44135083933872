.signatures-container.component-container {
  .signature-title-container {
    background-color: #f2f2f2;
    padding: 5px 10px !important;
    margin-bottom: 10px;

    .checked-by-text {
      font-size: calc(11px + 6 * var(--width-ratio));
      font-weight: 600;
      color: #5c7c8d;
    }
  }

  .signature-container {
    break-inside: avoid;

    .signature-image-box {
      display: inline-block;
      box-sizing: border-box;
      width: 23%;
      margin: 0 1%;
      border: 1px solid #5c7c8d;
      padding: 10px;
      vertical-align: top;

      .signature-image {
        display: block;
        width: 100%;
      }

      .signature-name {
        min-height: 19px;
        margin-top: 5px;
        font-size: 12px;
        color: rgb(92, 124, 141);
      }
    }
  }
}
