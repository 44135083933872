.KioskPhonePadScreen {
  .PhoneInput {
    float: left;

    .KioskTitle {
      margin-bottom: 30px;
    }

    .PhoneNumber {
      display: inline-block;
      border-radius: 4px;
      background-color: rgba(224, 225, 226, 0.2);
      padding: 60px 24px;
      font-size: 85px;
      text-align: center;
      line-height: 95px;

      div {
        display: inline-block;
        width: 69px;
        height: 100px;
        vertical-align: top;
        color: #1c3e52;
        margin-right: 16px;
        font-size: 74px;
        border-bottom: 4px solid #1c3e52;

        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(7) {
          margin-right: 40px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  .NumPad2 {
    float: none;
  }
}
