.grid.desk-optional-items-container.component-container {
  margin: 0.5em 0;
  padding: 0;

  @media (max-width: 1223px) {
    margin: 0.5em 2.5%;
  }

  .optional-items-header {
    .optional-items-header-icon {
      > svg {
        font-size: calc(24px + 6 * var(--width-ratio));
        color: #234457;
        margin-top: 3px;
      }
    }

    .optional-items-header-title {
      display: flex;
      flex-direction: column;
      margin: 0 0 0 8px;

      > h4 {
        display: inline-block;
        font-size: calc(24px + 6 * var(--width-ratio));
        color: #1c3e52;
      }
    }

    .optional-items-header-subtitle {
      width: 100%;

      > p {
        margin-top: 5px;
        font-size: calc(17px + 6 * var(--width-ratio));
        color: #5c7c8e;
        font-weight: 500;
      }
    }
  }

  .optional-items-heading {
    padding: 18px 18px 5px 18px;

    .optional-items-heading-status-container {
      .optional-items-heading-status-item {
        display: inline-block;
        margin-right: 18px;
        margin-bottom: 3px;

        .optional-items-heading-status-text {
          color: #9cabb4;
          font-size: 14px;
          text-transform: uppercase;
          margin-left: 7px;
        }
      }
    }

    .column > span {
      color: #234457;
      opacity: 0.42;
      font-size: 16px;
      text-transform: uppercase;
    }
  }

  .optional-items-rows {
    padding: 0;

    .optional-items-row {
      width: 100%;
      border-radius: 5px;
      margin: 0 0 8px 0;
      min-height: 76px;
      background-color: white;
      padding: 18px 15px;
      border: 1.5px solid grey;
      align-items: center;

      @media (max-width: 1223px) {
        padding: 15px;
        align-items: center;
      }

      &:last-child {
        margin-bottom: 0;
      }

      &.green {
        border: 1.5px solid green;
      }

      &.red.highlight,
      &.orange.highlight {
        border: 1.5px solid red;
        background-color: #ffe5e5;

        .action-buttons-container .action-buttons-cart,
        .action-buttons-container .action-buttons-contact {
          &:not(.selected) {
            background-color: #ffe5e5;
          }
        }
      }

      .optional-items-row-title {
        display: flex;
        padding-right: 20px;

        .condition-icon {
          margin-right: calc(12px + 6 * var(--width-ratio));
          flex: none;
        }

        .expandable-container {
          display: contents;

          svg {
            margin-top: calc(6px + 6 * var(--width-ratio));
          }

          span {
            padding-top: calc(4px + 6 * var(--width-ratio));
            font-size: calc(17px + 3 * var(--width-ratio));
            color: #1c3e52;

            &.collapsed {
              height: auto;
            }
          }
        }
      }

      .optional-items-tire-title {
        display: flex;
        font-size: calc(18px + 3 * var(--width-ratio));
        margin-bottom: calc(6px + 5 * var(--width-ratio));

        svg {
          margin-right: 7px;
        }

        span {
          color: #6a8796;
        }
      }

      .optional-items-row-remark {
        padding-right: 20px;

        @media (max-width: 1223px) {
          margin-top: 7px;
          padding-right: 0;
        }
      }

      .optional-items-row-price {
        padding-top: calc(4px + 6 * var(--width-ratio));
        display: flex;
        justify-content: center;

        @media (max-width: 1223px) {
          justify-content: flex-start;
          padding-top: 0;
          margin: 20px 0;
        }
      }

      .optional-items-row-gallery {
        text-align: center;

        @media (max-width: 1223px) {
          text-align: left;
          margin-bottom: 10px;
        }
      }

      .optional-items-row-remark,
      .optional-items-row-price {
        font-size: calc(14px + 4 * var(--width-ratio));
        color: #5b7b8c;
      }

      .gallery-button-label {
        @media (max-width: 1223px) {
          margin-bottom: 4px;
        }
      }

      .action-buttons-container {
        @media (max-width: 1223px) {
          margin-bottom: 4px;
        }
      }

      .optional-tyre-items-replacement-rows {
        margin: 0;
        padding: 0;
        width: 100%;

        > .row:nth-child(2)::before {
          height: 0;
        }

        .optional-tyre-items-replace-icon-spacer {
          display: inline-block;
          margin-right: calc(12px + 6 * var(--width-ratio));
          width: calc(26px + 14 * var(--width-ratio));
          height: calc(26px + 14 * var(--width-ratio));
        }

        .optional-tyre-items-replace-container {
          margin-top: calc(14px + 14 * var(--width-ratio));
          padding: 0;

          .optional-tyre-items-replace-icon-spacer {
            height: auto;
          }
        }

        .optional-tyre-items-replace-with {
          color: #0f39cc;
          text-transform: uppercase;
          font-style: italic;
          font-weight: 700;
          font-size: calc(16px + 3 * var(--width-ratio));
        }

        .optional-tyre-items-replacement-row {
          padding: 0;

          @media (max-width: 1223px) {
            align-items: center;
          }

          .optional-tyre-items-replacement-column {
            display: flex;

            > div {
              display: inline-block;
            }

            .optional-tyre-items-row-title-container {
              padding-right: 20px;
              width: calc(100% - calc(26px + 14 * var(--width-ratio)));
            }
          }

          .optional-tyre-items-row-remark-column {
            .expandable-container {
              margin-top: 0;
            }

            @media (max-width: 1223px) {
              margin: 0;
            }
          }

          .optional-tyre-items-row-price {
            margin: 0;
          }
        }
      }
    }

    .optional-items-answers-icons-row {
      display: flex;
      align-items: center;
    }
  }

  .optional-items-condition-icon-container {
    padding-left: 0;
  }
}
