.thank-you-container {
  .modal-content {
    padding: 0;
    border-radius: 0;
  }
  .thank-you-outter-container {
    margin-top: 1em;
  }

  .thank-you-header-container {
    min-height: 20em;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 1px solid #b1b8bc;
    margin: 1em auto;
    padding: 1em;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.3em;
  }

  .thank-you-info-container {
    border: 1px solid #b1b8bc;
    width: 95%;
    margin: 1em auto;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0.3em;
  }

  .thank-you-icon-container {
    text-align: center;
    .thank-you-icon {
      font-size: 70px;
      margin-bottom: 0.25em;
    }
  }

  .thank-you-title {
    padding: 20px;
    word-wrap: break-word;
    text-align: center;
    font-size: 25px;
    font-weight: 500;
    color: #1c3e52;
    margin-bottom: 0.55em;
  }

  .thank-you-text {
    font-size: 15px;
    color: #5c7c8d;
    text-align: center;
    padding-bottom: 2em;
  }

  .thank-you-info-title {
    text-align: left;
    padding: 0.5em;
    background-color: #f3f5f7;
    color: #234457;
    padding: 0.5em 1em;
  }

  .thank-you-info-content-container {
    background-color: #fff;
    padding: 0.5em 1em;
  }

  .thank-you-info-text {
    color: #5c7c8d;
    text-align: left;
    margin-bottom: 0.7em;

    svg {
      min-width: 16px;
      margin-right: 10px;
    }

    a {
      font-size: 17px;
      color: #5c7c8d;

      img {
        width: 30px;
        margin-right: 5px;
        vertical-align: middle;
        margin-left: -5px;
      }
    }
  }

  .thank-you-info-text-data {
    font-weight: 500;
  }

  .thank-you-success-btn-container {
    width: 95%;
    margin: auto;

    .thank-you-success-btn-large {
      width: 100%;
      padding: 1em;
      color: #fff;
      cursor: pointer;
    }

    .thank-you-success-check {
      margin-left: 0.5em;
    }
  }

  .keylocker-pin-code-container {
    width: 95%;
    margin: 1.1em auto;

    .message {
      text-align: center;
      font-size: 1.15rem;
      color: #1e561f;
      background-color: #fcfff5;
      border: 1px solid #e0e1e2;
      border-radius: 10px;
      box-shadow: none;
      padding: 2rem 6rem;

      @media (max-width: 1223px) {
        padding: 2rem;
      }
    }

    h3 {
      margin-top: 5px;
      font-size: 2rem;
    }

    .pin-code {
      font-weight: 600;
    }
  }
}
