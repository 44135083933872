.StartScreen {
  margin: 0 auto;
  text-align: center;

  .KeyLockerTitle {
    text-align: center;
    width: 100%;
  }

  .StartScreenSubtitle {
    font-size: 40px;
    line-height: 55px;
    text-align: center;
    width: 100%;
    margin-bottom: 100px;
  }

  .StartScreenOptions {
    margin: 0;

    &:first-child {
      .StartScreenOption {
        margin-left: 0px;
      }
    }

    &:last-child {
      .StartScreenOption {
        margin-right: 0px;
      }
    }

    .StartScreenOption {
      display: inline-flex;
      background-color: rgba(224, 225, 226, 0.2);
      border-radius: 37px;
      margin-left: 15px;
      margin-right: 15px;
      width: 300px;
      height: 338px;
      vertical-align: middle;
      justify-content: center;
      align-items: center;

      .StartScreenOptionContent {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 8px solid #3ca753;
        border-radius: 37px;

        .StartScreenSingleIcon {
          width: 80px;
          height: 60px;
          color: #1ba753;
        }

        .StartScreenIcon {
          width: 100%;
          height: 40%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          svg.svg-inline--fa.fa-arrow-down.fa-w-14.StartScreenSingleIcon {
            color: #3ca753;
          }
        }

        .StartScreenOptionTitle {
          font-size: 28px;
          line-height: 38px;
          width: 90%;
          font-weight: 600;
        }
      }

      &:nth-child(3) .StartScreenSingleIcon {
        font-size: 45px;
      }
    }
  }
}

.KeylockerQRCode_Container {
  background-color: grey;
}
