.simple-detail-analysis-container {
  border: 2px solid rgba(35, 68, 87, 0.4);
  border-radius: 0.3em;
  background-color: #fff;
  margin: 1em 1.4rem 0em 1.4rem;
  box-sizing: border-box;
  padding: 1em;

  h4 {
    margin: 0 0 0.5em 0;
    color: #1c3e52;
    font-weight: 600;
    font-size: calc(24px + 6 * var(--width-ratio));
    display: inline-block;
  }

  .ui.grid {
    padding: 1em;
  }

  .title {
    font-size: calc(13px + 3 * var(--width-ratio));
    font-weight: 500;
    color: #bcc9d0;
    text-transform: uppercase;
  }

  .simple-detail-analysis-row-content-title {
    font-size: calc(18px + 2 * var(--width-ratio));
    color: #1c3e52;
  }

  .simple-detail-analysis-row-content {
    font-size: calc(14px + 4 * var(--width-ratio));
    color: #5b7b8c;

    &.letter {
      font-weight: 600;
    }
  }

  .simple-detail-analysis-row {
    border-bottom: 2px solid #23445712;
    padding: 8px 0px !important;
  }

  .simple-detail-analysis-row:last-child {
    border-bottom: none;
  }
}
