.order-container {
  @media (max-width: 1223px) {
    border: 1px solid #b1b8bc;
    border-radius: 3px;
    margin: 0 7px;
  }

  .order-scheduled_services-heading,
  .order-optional_services-heading,
  .order-optional_items-heading {
    padding: 0.75em;
    background-color: #e4e7eb;
    color: #1c3e52;
    font-size: calc(18px + 4 * var(--width-ratio));
    font-weight: bold;
  }

  .order-scheduled_services-container,
  .order-optional_services-container,
  .order-optional_items-container {
    padding: 1em 1em 0.5em 1em;
    background-color: white;

    .order-scheduled_services-item,
    .order-optional_services-item,
    .order-optional_items-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e9eb;
      padding: 0.3em 0;

      &:last-child {
        border-bottom: none;
      }

      .order-scheduled_services-item-title,
      .order-optional_services-item-title,
      .order-optional_items-item-title {
        width: 70%;
        font-size: calc(13px + 2 * var(--width-ratio));
        color: #1c3e52;
        word-break: break-word;

        &.contact_me {
          width: 100%;
        }
      }

      .order-scheduled_services-item-price,
      .order-optional_services-item-price,
      .order-optional_items-item-price {
        font-size: calc(11px + 2 * var(--width-ratio));
        color: #5c7c8d;
      }
    }
  }

  .order-total-amount-container {
    padding: 1.5em 1em;
    background-color: #f3f5f7;
    opacity: 0.84;

    .order-total-amount-money-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;

      .order-total-amount-money-title,
      .order-total-amount-money-amount {
        color: #234457;
        font-size: calc(14px + 2 * var(--width-ratio));
      }
    }

    .order-total-amount-vat-container {
      display: flex;
      justify-content: space-between;

      .order-total-amount-vat-title,
      .order-total-amount-vat-amount {
        color: #5c7c8d;
        font-size: calc(11px + 2 * var(--width-ratio));
      }
    }

    .download-bill-link {
      margin-top: 12px;
      font-size: 15px;
      text-decoration: underline;
      color: #234457;
      cursor: pointer;
    }
  }

  .order-terms-container {
    padding: 0em 0em 1em 0.5em;
    margin-top: 1em;

    .order-terms-item {
      display: flex;
      align-items: center;

      .ui.fitted.checkbox.big-box {
        min-width: fit-content;
        margin: 0px 5px 10px 0px;

        label,
        label::before,
        label::after {
          width: 51px;
          height: 51px;
        }

        label::after {
          font-size: 36px;
          line-height: 51px;
        }

        label::before {
          border: 1px solid #b8b9bb;
        }
      }

      &:last-child {
        margin-bottom: 0em;
      }

      .ui.button.order-terms-btn {
        font-size: calc(14px + 2 * var(--width-ratio));
        color: #5c7c8d;
        background-color: transparent;
        text-decoration: underline;
        cursor: pointer;
        padding: 1px 0 15px 5px;
        text-align: left;
      }
    }

    .order-terms-error {
      color: red;
      flex: 1;
      font-size: 9px;
      margin-bottom: 0.7em;
    }
  }

  .order-confirm-container {
    text-align: center;
    margin: 1em 0em;

    button.ui.button {
      min-width: 55%;
      font-weight: 400;
      font-size: calc(12px + 2 * var(--width-ratio));
      color: #fff;
    }
  }

  .customer-name-container {
    padding: 10px;
    margin-bottom: 1em;

    &.required {
      .ui.input input {
        border-color: red;
      }
    }

    label {
      font-size: 13px;
      color: #5c7c8d;
      margin-bottom: 0.3em;
    }
  }

  .acses-container {
    padding: 10px;
    margin-top: 1em;
    display: flex;
    align-items: center;

    label {
      font-size: 16px;
      line-height: 16px;
      color: #5c7c8d;
      margin-bottom: 0.3em;
    }

    .ui.fitted.checkbox {
      min-width: fit-content;
      margin-right: 7px;

      label,
      label::before,
      label::after {
        width: 51px;
        height: 51px;
      }

      label::after {
        font-size: 36px;
        line-height: 51px;
      }

      label::before {
        border: 1px solid #b8b9bb;
      }
    }
  }
}

@media (max-width: 1223px) {
  h4.mobile-order-header {
    color: #1c3e52;
    margin: 12px 0 12px 14px;
    font-size: 20px;
  }

  .order-container {
    .order-optional_services-remarks {
      padding: 0 0.8em;
      background-color: white;

      .order-mobile-remarks-container {
        padding: 1.3em 0 0.8em 0;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e9eb;

        &:last-child {
          border-bottom: none;
        }

        .order-mobile-remarks-icon {
          color: #234457;
          font-size: 1em;
          margin-right: 0.3em;
        }

        .order-mobile-remarks-title {
          color: #234457;
          font-size: 1em;
        }
      }
    }

    .order-scheduled_services-item {
      padding-bottom: 0.5em;

      .order-scheduled_services-item-title {
        font-size: 15px;
        margin: 0em;
        padding: 0.3em;
      }

      .order-scheduled_services-item-price {
        font-size: 13px;
      }
    }

    .order-optional_services-item,
    .order-optional_items-item {
      padding-bottom: 0.5em;

      .order-optional_services-item-title,
      .order-optional_items-item-title {
        font-size: 15px;
        margin: 0em;
        padding: 0.3em;
      }

      .order-optional_services-item-price,
      .order-optional_items-item-price {
        font-size: 13px;
      }
    }

    .order-total-amount-container {
      padding: 1.5em 0.5em;

      .order-total-amount-money-container {
        .order-total-amount-money-title {
          color: #234457;
          font-size: 16px;
          margin-bottom: 0.8em;
        }

        .order-total-amount-money-amount {
          color: #234457;
          font-size: 16px;
        }
      }

      .order-total-amount-vat-container {
        .order-total-amount-vat-title {
          color: #5c7c8d;
          font-size: 13px;
          font-weight: 400;
        }

        .order-total-amount-vat-amount {
          color: #5c7c8d;
          font-size: 11px;
          font-weight: 400;
        }
      }
    }
  }
}
