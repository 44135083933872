.KioskCheckInfoScreen {
  .CheckInfoMessage {
    font-size: 52px;
    line-height: 71px;
    margin: 24px 0;
  }

  .ui.button.KioskNavigationButton {
    width: 636px;
    border-radius: 8px;
    margin: 0 auto;
    font-size: 40px;
    margin-top: 90px;
    padding: 40px;
  }

  .NavigationButtonContainer {
    display: flex;
    justify-content: center;
  }

  .KioskTitle {
    display: flex;
  }

  .KioskTitleIcon {
    margin-right: 30px;
    font-size: 50px;
    color: #21ba45;
  }

  .CheckInfoDetail {
    font-size: 52px;
    line-height: 71px;
    background-color: rgba(224, 225, 226, 0.2);
    border-radius: 4px;
    display: inline-block;
    padding: 18px;

    .icon {
      font-size: 36px;
      margin-right: 7px;

      &.phone {
        vertical-align: top;
      }
    }
  }

  .CheckInfoExtraPhone {
    margin: 48px 0 5px 0;
    font-size: 52px;
    line-height: 71px;
  }
}
