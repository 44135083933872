.add-remark-upload-file-button-container {
  text-align: center;

  .add-remark-upload-file-button.ui.button {
    color: #fff;
    width: 50%;
    font-weight: 300;
    padding: 0.75em 0.75em;
  }
}
