.StepContainer {
  background-color: rgba(27, 167, 83, 0.1);
  border-radius: 37px;
  margin-left: 13px;
  margin-right: 13px;
  width: 344px;
  height: 256px;
  margin-bottom: 59px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;

  &.-size-large {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    height: auto;
    margin-bottom: 106px;

    .Step {
      &__title {
        height: auto;

        p {
          font-size: 51px;
          line-height: 55px;
          margin-bottom: 48px;
        }
      }
    }
  }

  .Step {
    &__number {
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 64px;
      width: 64px;
      max-width: 108px;
      height: 53px;
      background-color: #1ba753;
      border-radius: 37px;
      color: white;
      margin: 0 auto;
      padding: 6px 0;
      position: absolute;
      top: -22px;
      right: 0;
      left: 0;
      font-size: 28px;
      line-height: 38px;
    }

    &__icon {
      font-size: 60px;
      color: #1ba753;
      margin: 0 auto;
      margin-top: 65px;
    }

    &__title {
      height: auto;

      p {
        font-size: 28px;
        line-height: 34px;
        width: 100%;
        padding: 0 12px;
        margin-bottom: 40px;
        margin-top: 35px;
        color: #1c3e52;
      }
    }
  }

  &__child-elements {
    padding: 40px 0;
  }
}
