.AuthenticationOptionsHelp {
  .AuthenticationOptionsHelpCode {
    display: inline-block;
    border-radius: 4px;
    background-color: rgba(224, 225, 226, 0.2);
    padding: 12px 25px;
    font-size: 64px;
    line-height: 87px;
    letter-spacing: 15px;
    margin-left: 50px;
  }

  .icon {
    font-size: 12px;
    color: white;
    background-color: #db2828;
    box-shadow: none;
    vertical-align: middle;
  }
}
