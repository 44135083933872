.direct-checkin-pop-up-modal {
  width: 50% !important;

  .modal-content {
    padding: 0;
    border-radius: 0;
  }
}

.checkin-modal-header-container {
  color: #5c7c8d;
  margin-bottom: 1.5em;
  padding: 2em 0em 0em 3em;

  .checkin-modal-header-logo-container {
    margin-bottom: 2em;
  }

  .checkin-modal-header-title {
    font-size: 36px;
  }

  .checkin-modal-header-text {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 0em;
  }

  .checkin-modal-welcome-text {
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 1em;
  }

  .checkin-modal-header-email-phone-container {
    font-size: 22px;

    .checkin-modal-header-info-text {
      font-weight: 500;
    }
  }
}

.checkin-modal-contact-info-container {
  color: #5c7c8d;
  margin-bottom: 1em;

  .checkin-modal-contact-info-edit {
    padding: 0em 1em 0em 3em;

    @media (max-width: 1223px) {
      padding: 0;
    }
  }

  .checkin-modal-contact-info {
    margin-bottom: 10px;
  }

  .checkin-modal-contact-text {
    font-size: 23px;
  }

  .input-field-container {
    padding: 10px;
    border: 2px solid;
    position: relative;
    border: solid 1px #3698d1;
    border-radius: 0.3em;
    margin-bottom: 4.5px;
    bottom: 4px;

    @media (min-width: 769px) {
      display: inline-block;
      width: 49%;
    }

    label {
      position: absolute;
      top: -12px;
      left: 20px;
      background-color: white;
      color: #3698d1;
    }

    .ui.input {
      width: 100%;

      input {
        border: none;
        outline: none;
        padding: 0.1em;
        font-size: 1em;
      }
    }
  }

  .input-field-container:nth-child(1) {
    margin-right: 10px;

    @media (max-width: 769px) {
      margin-right: 0px;
      margin-top: 15px;
    }
  }

  .input-field-container:nth-child(2) {
    @media (max-width: 769px) {
      margin-top: 15px;
    }
  }

  .preferred-channel-buttons-container {
    padding: 0em 1em 0em 3em;
    margin-bottom: 1em;

    @media (max-width: 1223px) {
      padding: 0;
    }

    @media (max-width: 769px) {
      padding: 0em 0em 0.3em 0em;
      margin-bottom: 0px;
    }

    .preferred-channel-button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 49%;
      background-color: #fff;
      border: 1px solid #e2e2e3;
      border-radius: 4px;
      padding: 8px;
      font-size: 19px;
      cursor: pointer;

      @media (max-width: 769px) {
        font-size: 14px;
        display: flex;
        width: 100%;
      }

      span {
        word-break: break-all;
        position: relative;
        top: 1px;
      }
    }

    .preferred-channel-button:nth-child(1) {
      margin-bottom: 10px;
      margin-right: 10px;
    }

    .preferred-channel-button:nth-child(2) {
      span {
        font-size: 19px;
        font-weight: 600;
      }

      .icon {
        font-size: 20px;
        vertical-align: 1px;
      }
    }

    .preferred-channel-button:nth-child(2) {
      @media (max-width: 769px) {
        span,
        .icon {
          font-size: 14px;
        }

        span {
          word-break: break-all;
          position: relative;
          top: 1.5px;
        }
      }
    }

    .active-preferred-channel {
      background-color: #55aa0d;
      color: #fff;
    }
  }
}

.checkin-modal-thanks-container {
  padding: 1em 0em 1.5em 3em;
  .checkin-modal-thanks-text {
    font-size: 22px;
    color: #5c7c8d;
  }
}

.checkin-modal-buttons-container {
  padding: 0em 0.5em 1em 0em;
  margin-top: 2em;
  display: flex;
  justify-content: flex-end;

  .continue-btn {
    color: #fff;
    font-weight: 300;
    margin-left: 0.5em;

    &:hover {
      color: #fff;
    }
  }
}

@media (min-width: 769px) and (max-width: 1440px) {
  .checkin-modal-header-container {
    .checkin-modal-header-title {
      font-size: 25px;
    }

    .checkin-modal-header-text {
      font-size: 19px;
    }

    .checkin-modal-welcome-text {
      font-size: 19px;
    }

    .checkin-modal-header-email-phone-container {
      font-size: 19px;
    }
  }

  .checkin-modal-contact-info-container {
    .checkin-modal-contact-text {
      font-size: 18px;
    }
  }

  .checkin-modal-thanks-container {
    .checkin-modal-thanks-text {
      font-size: 18px;
    }
  }
}

@media (max-width: 1223px) {
  .checkin-modal-header-container {
    .checkin-modal-header-title {
      font-size: 20px;
    }

    .checkin-modal-header-text {
      font-size: 17px;
    }

    .checkin-modal-welcome-text {
      font-size: 17px;
    }

    .checkin-modal-header-email-phone-container {
      font-size: 15px;
    }
  }

  .checkin-modal-header-container,
  .checkin-modal-contact-info-container,
  .checkin-modal-contact-text,
  .checkin-modal-thanks-container {
    padding: 0em;
  }

  .checkin-modal-contact-info-container {
    .checkin-modal-contact-text {
      font-size: 15px;
    }
  }

  .checkin-modal-thanks-container {
    .checkin-modal-thanks-text {
      font-size: 15px;
    }
  }

  .checkin-modal-buttons-container {
    padding: 0em;
    button {
      width: 50%;
      font-size: 1.3em;
    }

    .popup-btn {
      padding: 1em;
      font-size: 1.3em;
      margin-right: 1.5em;
    }

    .continue-btn {
      font-size: 1.3em;
    }
  }

  .direct-checkin-pop-up-modal {
    width: 95% !important;
    margin: auto;
  }
}

.preferred-channel-title {
  font-size: 20px;
  color: #5c7c8d;
  margin-bottom: 5px;
  padding-left: 2.1em;

  @media (max-width: 769px) {
    padding-left: 0em;
  }
}

.online-checkin-info-container {
  border: 1px solid #b1b8bc;
  width: 100%;
  margin: 1em auto;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 0.3em;
}

.online-checkin-icon-container {
  text-align: center;
  .online-checkin-icon {
    font-size: 70px;
    margin-bottom: 0.25em;
  }
}

.online-checkin-title {
  padding: 20px;
  word-wrap: break-word;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  color: #1c3e52;
  margin-bottom: 0.55em;
}

.online-checkin-text {
  font-size: 15px;
  color: #5c7c8d;
  text-align: center;
  padding-bottom: 2em;
}

.online-checkin-info-title {
  text-align: left;
  padding: 0.5em;
  background-color: #f3f5f7;
  color: #234457;
  padding: 0.5em 1em;
}

.online-checkin-info-content-container {
  background-color: #fff;
  padding: 0.5em 1em;
}

.online-checkin-info-text {
  color: #5c7c8d;
  text-align: left;
  margin-bottom: 0.7em;

  svg {
    min-width: 16px;
    margin-right: 10px;
  }

  a {
    font-size: 17px;
    color: #5c7c8d;

    img {
      width: 30px;
      margin-right: 5px;
      vertical-align: middle;
      margin-left: -5px;
    }
  }
}

.online-checkin-info-text-data {
  font-weight: 500;
}

.OnlineCheckinContactUsSection:first-child {
  margin-left: 2px;
}

@media (max-width: 1223px) {
  .OnlineCheckinContactUsSection,
  .OnlineCheckinContactUsSection span {
    width: 100%;
    display: block;
    margin-left: 0 !important;
    margin-top: 10px;
  }
}

@media (min-width: 769px) {
  .popup-modal {
    position: relative;
    .online-checkin-contact-us-section-box {
      width: 90%;
      display: flex;
      justify-content: space-between;
      margin-top: 1em;
      position: absolute;
      bottom: 1em;
    }
  }
}
