.condition-icon {
  display: inline-block;
  width: calc(26px + 14 * var(--width-ratio));
  height: calc(26px + 14 * var(--width-ratio));
  border-radius: 3px;
  margin: 0;
  text-align: center;
  break-inside: avoid;

  svg.svg-inline--fa {
    width: calc(26px + 14 * var(--width-ratio));
    height: calc(26px + 14 * var(--width-ratio));
    padding: calc(4px + 5 * var(--width-ratio));
  }

  &.green {
    background-color: #6eb160;
  }

  &.orange {
    background-color: orange;
  }

  &.red {
    background-color: red;
  }

  &.light-green {
    background-color: #83d55f;
  }

  &.gray {
    background-color: gray;
  }

  &.small {
    width: 23px;
    height: 23px;

    svg {
      width: 23px;
      height: 23px;
      padding: 5px;
    }
  }

  &.medium {
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
      padding: 7px;
    }
  }

  &.big {
    width: 40px;
    height: 40px;

    svg {
      width: 40px;
      height: 40px;
      padding: 10px;
    }
  }
}
