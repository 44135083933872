.MaintenanceContainer {
  min-height: 100vh;
  height: 100%;
  background: #f2f5f7 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;

  .Maintenance {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    .header {
      color: #5c7c8d;
      margin-bottom: 30px;

      & div:first-child {
        font-size: 80px;
        line-height: 80px;
      }

      & div:nth-child(2) {
        font-size: 24px;
        line-height: 24px;
      }
    }

    img {
      width: 100%;
    }
  }
}
