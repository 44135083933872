.report-not-fixed-container.component-container {
  .not-fixed-heading {
    margin-bottom: 5px;
    break-inside: avoid;

    .not-fixed-heading-title-container {
      display: flex;
      align-items: center;
      float: left;

      .not-fixed-heading-icon {
        font-size: calc(24px + 6 * var(--width-ratio));
        margin-right: 5px;
        margin-bottom: 3px;
        color: #234457;
      }

      .not-fixed-heading-title {
        margin: 0em;
        color: #1c3e52;
        font-weight: 500;
        font-size: calc(24px + 6 * var(--width-ratio));
        display: inline-block;
        vertical-align: middle;
        height: fit-content;
      }
    }

    .not-fixed-heading-status-container {
      text-align: right;
      float: right;
      margin-top: 2px;

      .not-fixed-heading-status-item {
        display: inline-flex;
        align-items: center;
        &:not(:last-child) {
          margin-right: 15px;
        }

        .not-fixed-heading-status-text {
          color: #9cabb4;
          font-size: 12px;
          text-transform: uppercase;
          margin-left: 7px;
          padding-top: 2px;
        }
      }
    }
  }

  .not-fixed-header {
    font-size: calc(12px + 4 * var(--width-ratio));
    font-weight: 500;
    color: #bcc9d0;
    text-transform: uppercase;
    break-inside: avoid;

    .column {
      break-inside: avoid;
    }
  }

  .row.not-fixed-row {
    padding: 4px 0px !important;
    break-inside: avoid;

    &:last-child {
      border-bottom: none;
    }

    .expandable-container {
      padding-right: 20px;
    }

    .not-fixed-title {
      color: #1c3e52;
    }

    .not-fixed-description {
      font-size: calc(14px + 4 * var(--width-ratio));
      color: #5b7b8c;

      .expandable-container {
        margin-right: 20px;
      }
    }

    .not-fixed-price {
      font-size: calc(13px + 5 * var(--width-ratio));
      color: #5b7b8c;
    }

    .not-fixed-status {
      text-align: center;
    }
  }

  .row.description-row {
    border-bottom: 2px solid #23445712;
    color: #5b7b8c;
    padding: 4px 0px !important;
    margin-top: 10px;
    margin-bottom: 5px;
    break-inside: avoid;
  }
}
