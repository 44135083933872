.ui.label.gallery-button-label {
  cursor: pointer;
  width: 62px;
  height: 34px;
  font-size: 16px;
  font-weight: 500;
  padding: 0.5em 0;
  text-align: center;

  svg {
    margin-right: 11px;
  }

  @media (max-width: 1223px) {
    width: 70px;
    height: 40px;
    font-size: 20px;

    svg {
      margin-right: 15px;
    }
  }
}
