.desk-checkin-order-terms-container {
  margin-top: 15px;
  padding: 15px 1.5rem;

  .desk-checkin-order-terms-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;

    .ui.fitted.checkbox.big-box {
      min-width: fit-content;
      margin-right: 5px;

      label,
      label::before,
      label::after {
        width: 51px;
        height: 51px;
      }

      label::after {
        font-size: 36px;
        line-height: 51px;
      }

      label::before {
        border: 1px solid #b8b9bb;
      }
    }

    &:last-child {
      margin-bottom: 0em;
    }

    .ui.button.desk-checkin-order-terms-btn {
      font-size: 14px;
      color: #5c7c8d;
      background-color: transparent;
      text-decoration: underline;
      cursor: pointer;
      padding: 1px 0 5px 5px;
      text-align: left;
    }

    .desk-checkin-order-terms-error {
      color: red;
      font-size: 14px;
      line-height: 22px;
      font-size: 12px;
      margin-left: 5px;
    }
  }

  @media (max-width: 780px) {
    .desk-checkin-agreement-name-and-error-container {
      display: flex;
      flex-direction: column;
    }
  }

  .agreement-name {
    font-size: 14px;
    line-height: 24px;
    text-decoration: underline;
    margin-left: 5px;
    vertical-align: middle;
    margin-bottom: 2px;
  }
}
