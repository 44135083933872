.KeylockerDropKeyQRCodeContainer {
  display: flex;
  justify-content: center;
  align-items: center;

  .KeylockerDropKeyQRCode {
    width: fit-content !important;
    height: fit-content;
    padding: 5em;
    background-color: gray;
    border-radius: 0.5em;
  }
}
