.expandable-tire-container {
  margin-top: calc(4px + 5 * var(--width-ratio));
  width: 100%;
  color: #1c3e52;

  .expandable-tire-mobile-container {
    display: flex;

    .expandable-tire-mobile-header {
      margin-top: calc(4px + 5 * var(--width-ratio));
      width: 100%;
      color: #1c3e52;
    }
  }

  .expandable-tire-header {
    display: flex;
    justify-content: space-between;
    font-size: calc(17px + 3 * var(--width-ratio));
  }

  .expandable-tire-icon {
    font-size: 16px;
  }

  .expandable-tire {
    margin-top: 1rem;

    .expandable-tire-details {
      margin-bottom: calc(6px + 5 * var(--width-ratio));

      span {
        font-size: calc(18px + 3 * var(--width-ratio));
        color: #6a8796;
        margin-left: 8px;
      }

      svg {
        font-size: calc(18px + 3 * var(--width-ratio));
      }
    }
  }
}
