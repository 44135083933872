.support-container {
  margin: 1em 0em;

  .support-title {
    color: #1c3e52;
    font-weight: 700;
    font-size: calc(20px + 14 * var(--width-ratio));
  }

  .support-text {
  }
}
