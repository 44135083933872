.desk-checkin-order-total-amount-container {
  background-color: #f3f5f7;
  padding: 15px 1.5rem;
  opacity: 0.84;

  .desk-checkin-order-total-amount-money-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    .desk-checkin-order-total-amount-money-title {
      color: #234457;
      font-size: 18px;
      line-height: 22px;
    }

    .desk-checkin-order-total-amount-money-amount {
      color: #234457;
      font-size: 18px;
      line-height: 22px;
    }
  }

  .desk-checkin-order-total-amount-vat-container {
    display: flex;
    justify-content: space-between;

    .desk-checkin-order-total-amount-vat-title {
      color: #5c7c8d;
      font-size: 12px;
      line-height: 15px;
    }

    .desk-checkin-order-total-amount-vat-amount {
      color: #5c7c8d;
      font-size: 12px;
      line-height: 15px;
    }
  }
}
