.NumPad {
  width: 458px;
  height: 616px;
  display: inline-grid;
  grid-template-columns: auto auto auto;
  grid-gap: 18px;
  margin-top: 50px;

  > button {
    height: 141px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    -webkit-tap-highlight-color: transparent;

    &.num {
      width: 141px;
      font-family: "Lato", "Helvetica Neue", Arial, Helvetica, sans-serif;
      font-size: 75px;
      font-weight: 700;
      vertical-align: baseline;
      background: #f2f2f2 none;
      color: rgba(0, 0, 0, 0.6);
      border-radius: 4px;
      padding: 0px;
      margin: 0px;

      &.button-touch-highlight {
        transition: 0.1s all ease-in-out;
        background-color: #1c3e5293;
      }
    }

    &.erase-box {
      width: 305px;
      background-color: rgba(255, 165, 165, 0.2);
      grid-column-start: 2;
      grid-column-end: 4;
      border-radius: 4px;

      &.button-touch-highlight {
        transition: 0.1s all ease-in-out;
        background-color: rgba(255, 165, 165, 0.45);
      }

      .erase-icon {
        color: #db2828;
        width: 83px;
        height: 49px;
      }
    }
  }
}
