.KeyLocker {
  color: #1c3e52;
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  padding: 0 60px;

  .KeyLockerHeader {
    height: 100px;
    padding-top: 30px;
    padding-bottom: 10px;
    font-size: 28px;
    line-height: 38px;

    .KeyLockerHeaderStep {
      width: fit-content;
      color: white;
      border-radius: 37px;
      height: 42px;
      background-color: #1ba753;
      text-align: center;
      display: inline-block;
      margin: 14px 24px 14px 0px;
      padding: 0px 30px;
    }

    .KeyLockerHeaderTitle {
      display: inline-flex;
      align-items: center;
      margin: 14px 0;
    }

    .KeyLockerHeaderActions {
      display: inline-block;
      float: right;

      .button {
        font-size: 36px;
        border-radius: 4px;
        background-color: #f9f9f9;
      }
    }
  }

  .KeyLockerContent {
    height: calc(100vh - 100px - 210px);
    padding-top: 42px;

    .KeyLockerTitle {
      font-size: 72px;
      line-height: 98px;
      margin-bottom: 24px;
    }
  }

  .KeyLockerNavigation {
    height: 210px;
    padding: 39px 0 71px 0;

    .KeyLockerNavigationLeftButton {
      .button {
        float: left;
        height: 140px;
        font-size: 50px;
      }
    }

    .KeyLockerNavigationRightButton {
      .button {
        float: right;
        font-size: 50px;
      }
    }
  }
}
