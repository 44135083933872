.tyre-checks-container.component-container {
  .row {
    break-inside: avoid;
  }

  .tyre-checks-left-section-container {
    padding-right: 20px !important;
  }

  .tyre-checks-right-section-container {
    padding-left: 20px !important;
  }

  .tyre-checks-heading-container {
    display: flex;
    align-items: center;
    padding: 5px 12px;
    background-color: #d4e9fb;
    font-size: 24px;

    .tyre-checks-heading-icon {
      color: #1c3e52;
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-right: 5px;
    }

    .tyre-checks-heading-title {
      color: #1c3e52;
      font-size: calc(24px + 6 * var(--width-ratio));
      margin-top: 0px;
      font-weight: 500;
    }
  }

  .tyre-checks-sub-heading {
    background-color: #e3f1fc;
    padding: 5px 0px 5px 15px;
    display: flex;
    align-self: center;
    align-items: center;

    .tyre-checks-sub-heading-icon {
      margin-right: 5px;
      font-size: calc(17px + 6 * var(--width-ratio));
      color: #1c3e52;
    }

    .tyre-checks-sub-heading-title {
      font-size: calc(15px + 6 * var(--width-ratio));
      font-weight: 600;
      color: #1c3e52;
    }
  }

  .tyre-checks-section-heading-container {
    background-color: #f5f6f8;
    text-align: center;
    padding: 2px 0px;

    .tyre-checks-section-heading-title {
      color: #1c3e52;
      margin-right: 10px;
    }
  }

  .tyre-checks-section-content-container {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    margin-top: 20px;
    padding: 0px 15px;
    .tyre-checks-section-inner-container {
      display: inline-block;
      padding: 0 5px 0 0;

      &.right-tyre {
        text-align: right;
        padding: 0 0 0 5px;

        .tyre-checks-section {
          .right-profile {
            margin-right: 10px;
          }
        }
      }

      .tyre-checks-mad-descript-container {
        margin-bottom: 20px;
        .tyre-checks-man-descript {
          font-size: calc(11px + 6 * var(--width-ratio));
          color: #5b7b8c;
        }
      }

      .tyre-checks-section {
        margin-bottom: 10px;

        .tyre-checks-profile-text {
          color: #5b7b8c;
          margin-left: 10px;
        }

        .tyre-checks-section-box {
          display: inline-block;
          width: 40px;
          height: 40px;
          border: 2px solid;
          border-radius: 50%;
          text-align: center;
          line-height: 36px;
          font-weight: 600;
          font-size: 20px;
        }

        .orange-status {
          background-color: #fbdfca;
          border-color: #eb7a1f;
          color: #eb7a1f;
        }

        .green-status {
          background-color: #e5ffe8;
          border-color: #118b1f;
          color: #118b1f;
        }

        .red-status {
          background-color: #fbd1d1;
          border-color: #eb1f1f;
          color: #eb1f1f;
        }
      }
    }
  }
}
