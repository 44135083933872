.navigation-container {
  width: 100%;
  height: 75px;
  padding: 9px;
  background-color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;

  .back-btn {
    display: inline-block;
    text-align: center;
    background-color: #fff;
    color: #0f39cc;
    font-size: 1.2em;
    line-height: 1.2em;
    width: 50%;

    &.large {
      width: 100%;
      font-size: 1.3em;
      line-height: 1.15;
      padding: 1em 2em 1em 2em;
    }

    .back-arrow {
      margin-right: 0.5em;
      font-size: 1.2em;
      color: #0f39cc;
    }
  }

  .next-btn {
    width: 50%;
    text-align: center;
    background-color: #0f39cc;
    color: #fff;
    font-size: 1.2em;
    line-height: 1.2em;
    padding: 1em;
    border-radius: 0.3em;
    height: 100%;
    margin: 0;

    &.large {
      width: 100%;
      font-size: 1.3em;
      line-height: 1.15;
      padding: 1em 2em 1em 2em;
    }

    &:focus,
    &:active,
    &:visited,
    &:focus-within,
    &:hover {
      color: #fff;
      outline: none;
    }

    .next-arrow {
      margin-left: 0.5em;
      font-size: 1.2em;
    }
  }
}
