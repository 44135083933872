.OrderScreen {
  font-size: 28px;
  line-height: 38px;

  .OrderHeader {
    height: 50px;
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    .reg-num-box {
      float: left;
      width: 240px;
      height: 50px;
      margin-right: 30px;
      border: 2px solid #234457;
      border-radius: 3px;

      .reg-country {
        width: 31px;
        height: 100%;
        display: inline-block;
        text-align: center;
        background-color: #40a7ff;
        position: relative;

        .reg-country-text {
          color: #fff;
          font-size: 20px;
          line-height: 60px;
          bottom: 0px;
          position: absolute;
          width: 100%;
          height: 100%;
        }
      }

      .reg-num {
        width: calc(100% - 31px);
        height: 100%;
        display: inline-block;
        text-align: center;
        vertical-align: top;
        background-color: #efe24c;
        color: #234457;
        font-weight: 500;
        font-size: 30px;
        line-height: 50px;
      }
    }

    .OrderCarMake {
      float: left;
      font-size: 36px;
      color: #5c7c8d;

      .car-icon {
        font-size: 36px;
        margin-right: 10px;
        color: #0f39cc;
      }
    }
  }

  .OrderServices {
    height: 555px;
    overflow-y: auto;

    .OrderContainer {
      border: 3px solid #b1b8bc;
      border-radius: 8px;
      padding: 0 1.4rem;
      margin: 0 10px 10px 0;

      &:last-child {
        margin-bottom: 0;
      }

      .ContainerHeader {
        padding-top: 25px;

        .icon {
          font-size: 36px;
        }

        h4 {
          display: inline-block;
          margin: 0 0 0 10px;
          font-size: 36px;
          line-height: 42px;
        }
      }

      .ContainerHeading {
        padding-top: 10px;
        padding-bottom: 20px;

        .title {
          font-size: 24px;
          line-height: 24px;
          font-weight: 400;
          color: #adadad;
        }
      }

      .ContainerItem {
        border-bottom: 1px solid #e5e9eb;
        padding: 16px 0 16px 0;
        font-weight: 400;

        &:last-child {
          border-bottom: none;
        }
      }

      .price {
        font-size: 24px;
        color: #5c7c8d;
      }

      &.TotalAmount {
        padding: 0.4rem 1.4rem 1.4rem 1.4rem;
        margin: 0 10px 0 0;
        background-color: #f3f5f7;
        font-weight: 400;

        .row {
          padding-bottom: 0;

          .vat-name {
            font-size: 20px;
          }
        }
      }

      &.OrderRemarks,
      &.OrderCustomerName {
        padding: 1.4rem 1.4rem 2rem 1.4rem;
        margin: 10px 10px 0 0;

        .ContainerText {
          font-size: 24px;
          line-height: 33px;
          margin-top: 20px;
          word-break: break-all;
        }
      }

      &.OrderCustomerName.CustomerNameError {
        border: 1px solid red;
      }
    }
  }

  .OrderAgreements {
    padding: 1.4rem;
    margin-top: 10px;
    font-weight: 400;
    border: 3px solid #b1b8bc;
    border-radius: 8px;
    margin: 10px 10px 0 0;

    .agreement {
      margin-bottom: 28px;
      display: flex;
      align-items: center;

      .agreement-open-button {
        background-color: #fff;
        font-size: 28px;
        padding: 0px;
        margin-left: 45px;
        color: #1c3e52;
        text-decoration: underline;
        text-align: left;
      }

      .agreement-error {
        color: red;
      }

      &:last-child {
        margin-bottom: 0em;
      }

      .agreement-box {
        font-size: 24px;

        input,
        label:before,
        label:after {
          width: 51px;
          height: 51px;
        }

        label {
          height: 51px;
          display: flex;
          align-items: center;
        }

        label:after {
          top: 10px;
          font-size: 36px;
        }

        label::before {
          border: 1px solid #b8b9bb;
        }
      }
    }
  }
}

.OrderHelp {
  & div:not(:last-child) {
    margin-bottom: 40px;
  }
}
