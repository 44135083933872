@media (max-width: 1223px) {
  .custom-remarks-container {
    &.mobile {
      margin: 0.5em 2.5%;
      padding: 0 1rem;

      .add-remark-title-field {
        width: 100%;
      }

      .add-remark-description-field {
        width: 100%;
      }

      .custom-remarks-mobile-container {
        border: 1px solid #b1b8bc;
        border-radius: 0.3em;
        width: 95%;
        margin: 0.6em auto;
        padding: 0em 1em !important;
        background-color: #fff;
      }

      .add-remark-upload-files-mobile-container {
        border: 1px dashed #e2e2e2;
        border-radius: 6px;
        padding: 1em;
        height: 100%;
        margin: 1em auto;
      }

      .add-remark-upload-file-button-container {
        display: flex;
        justify-content: center;

        .add-remark-upload-file-btn {
          color: #fff;
          font-size: 1.2em;
          font-weight: 300;
          padding: 1em 2.5em;
          margin-top: 1em;
        }
      }

      .custom-remarks-header-container {
        width: 100%;
        height: 4em;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 0.3em;
        margin-bottom: 0.5em;

        .custom-remarks-header-icon {
          font-size: 20px;
        }

        h4 {
          margin: 1em 0em 1em 0.5em;
          font-size: 20px;
          color: #1c3e52;
        }
      }

      .add-remark-uploaded-files-mobile-container {
        height: auto;
        margin-top: 1.5em;

        .add-remark-uploaded-files-mobile-title {
          color: #25465a;
          font-weight: 900;
          font-size: 15px;
        }

        .add-remark-uploaded-files-item-container {
          display: flex;
          justify-content: space-between;
          margin-left: 0.5em;

          .add-remark-uploaded-files-file-container {
            display: flex;
            align-items: baseline;

            .add-remark-uploaded-files-image-icon {
              margin-right: 0.3em;
              color: #5b7b8c;
              font-size: 13px;
            }

            .add-remark-uploaded-files-attachement-name {
              word-break: break-word;
              color: #25465a;
              margin-bottom: 15px;
            }
          }

          .add-remark-uploaded-files-icon {
            cursor: pointer;
            color: #25465a;
            margin-top: 2px;
            margin-left: 5px;
          }
        }
      }

      .custom-remarks-title-container {
        display: flex;
        align-items: center;

        h4 {
          font-weight: 500;
        }
      }
    }
  }

  .add-remark-container {
    padding-left: 1rem;
    margin-bottom: 1rem;
  }
}
